import { generateUUID } from "three/src/math/MathUtils"
import { R1 } from "../libs/Math/R1"
import { R2 } from "../libs/Math/R2"
import { ModelComputer } from "./Model"
import { el, NodeRepr_t } from "@elemaudio/core"
import ela from "../Engines/AudioEngine/ela/ela"

export class ElasticBounceBetweenPoints1d {
  /**
   * The model we use here is simply a saw-tooth wave. Simple to compute in both places.
   */

  static getModel(
    point1: number,
    point2: number,
    velocity: number,
    startingPhase: number = -Math.PI / 2,
  ): ModelComputer<number> {
    const amplitude = Math.abs(point1 - point2) / 2

    const periodWallToWall = Math.abs(point1 - point2) / velocity
    const periodFullCycle = 2 * periodWallToWall

    // Move out t value along by the phase offset
    const phaseOffsetToT = (startingPhase / (2 * Math.PI)) * periodFullCycle

    return {
      valueAtT: (t: number) => {
        const phasedT = t + phaseOffsetToT

        const value = R1.triangleWave(
          phasedT,
          1 / periodFullCycle,
          amplitude,
          point1,
        )

        return value
      },
      newAudioComputer: (tElapsedOnStart: number) => {
        const t_elapsed_seconds = el.add(
          { key: generateUUID() },
          el.const({ value: tElapsedOnStart }),
          el.div(el.counter({ key: generateUUID() }, 1), el.sr()),
        )

        const phasedT = el.add(t_elapsed_seconds, phaseOffsetToT)

        const value = ela.triangleWaveEl(
          phasedT,
          1 / periodFullCycle,
          amplitude,
          point1,
        )

        return value
      },
    }
  }
}
