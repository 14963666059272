import Fidget, { PointEvent } from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { Bungee } from "../../libs/Math/BungeeNode"
import { R2 } from "../../libs/Math/R2"
import { FidgetId } from "../../constants/fidgetConsts"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

export default class BungeeFidget extends Fidget {
  bungees: Bungee.Bungee[] = []

  constructor(canvas: HTMLCanvasElement) {
    super(canvas, FidgetId.BUNGEE)

    // Init nodes and connects
    this.initNodes()
    this.initConnections()
  }

  initNodes() {
    // Init triangle
    // this.bungees = Bungee.constuctRandomBungees();
  }

  // addConnection(uid1: string, uid2: string) {

  //     AyisenMath.Graph.addConnection(uid1, uid2, this.connectionGraph);

  // }

  initConnections() {
    // this.connectionGraph = AyisenMath.Graph.generateRandomConnections(this.nodes);
  }

  onClick = (e: PointEvent) => {
    // Generate several more!
    this.bungees = this.bungees.concat(
      Bungee.constuctRandomBungees(
        R2.newVector(e.x / this.canvas.width, e.y / this.canvas.height),
      ),
    )
  }

  onDrag = (e: PointEvent) => {
    this.bungees = []

    // Add energy to nodes!
    // for (let nodeId of Object.keys(this.nodes)) {

    //     // Dist
    //     const dist = AyisenMath.R2.distance({x: this.nodes[nodeId].loc.x*this.canvas.width, y: this.nodes[nodeId].loc.y*this.canvas.height}, e);

    //     // Give energy inversely proportional to distance!

    //     // TODO @Marcel: Clean up
    //     // TODO @Marcel: Better units for energy, etc.

    //     // Normalize the distance moved, so that moving 1/10 of the screen will add 0.1 KE
    //     const normalizedDrag = AyisenMath.R2.normalize({x: e.movement.x / this.canvas.width, y: e.movement.y / this.canvas.height});
    //     let normalizedMove = normalizedDrag.mag / 0.1;
    //     normalizedMove *= 0.05;

    //     const energyBump = ( normalizedMove / Math.pow(dist, 1.25));

    //     // Now... i also want to be bumping this in the direction pulled...
    //     // I want to add the energy in the direction of the normalized drag
    //     // So I'll increase the velocity along that unit vector, scaled by this energy hop.
    //     const velocityBump = Math.sqrt( 2 * energyBump );
    //     const velocityUpdate = {
    //         x: normalizedDrag.norm.x * velocityBump,
    //         y: normalizedDrag.norm.y * velocityBump,
    //     }
    //     if (this.nodes[nodeId].loc.x < 1 && this.nodes[nodeId].loc.x > 0) {
    //         // console.log("Velocity Bump", velocityBump)
    //         // console.log("Normalized Drag", normalizedDrag)
    //         // console.log("Velocty Update", velocityUpdate)
    //     }

    //     this.nodes[nodeId].velocity = {
    //         x: this.nodes[nodeId].velocity.x + velocityUpdate.x,
    //         y: this.nodes[nodeId].velocity.y + velocityUpdate.y,
    //     }
    // }
  }

  // Animate frame
  renderFrame = async () => {
    if (!this.ctx) return

    // TODO @Marcel: Move this to the simulation properties!
    let smallScreen = false
    if (this.canvas.width < 500) {
      smallScreen = true
    }

    // Render / Update ripples

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    for (let bungee of this.bungees) {
      Object.values(bungee.nodes).forEach((node, idx) => {
        const connections = Bungee.getHydratedConnections(node, bungee)

        if (!this.ctx || !this.lastRenderT) return

        VisualEngine.canvas.drawBubble(
          this.ctx,
          R2.newVector(
            node.loc.x * this.canvas.width,
            node.loc.y * this.canvas.height,
          ),
          12,
          bungee.color,
          1,
        )

        // Draw line to siblings
        for (let connection of connections) {
          VisualEngine.canvas.drawLine(
            this.ctx,
            R2.newVector(
              node.loc.x * this.canvas.width,
              node.loc.y * this.canvas.height,
            ),
            R2.newVector(
              connection.loc.x * this.canvas.width,
              connection.loc.y * this.canvas.height,
            ),
            4,
            bungee.color,
          )
        }

        // Store the initial location
        node.prevLoc = { ...node.loc }

        const newBody = AyisenMath.Body.updateBodyLoc(
          { location: node.loc, velocity: node.velocity, width: 0 },
          this.lastRenderT,
          0.0,
          0.0,
        )
        node.loc = newBody.location
        node.velocity = newBody.velocity

        // TODO @marcel: If the connections of a given bungee are going to cross their angles when drawn out from the given vertex... Bounce them off angularly!!!
        // This will lead to really cool preservation of the shape's interior!!! :)

        // TODO @Marcel: Potentially have some tension running through these, so they spring together / appart when they get too close / far!

        // TODO: Add interactability (click to add? drag to create a new ring of nodes that connect up on release? (second might be really neat)).
      })

      // Parity check / bounce
      // Object.values(bungee.nodes).forEach((node, idx) => {
      //     Bungee.checkForAngleParityViolation(node);
      // })
      Object.values(bungee.nodes).forEach((node, idx) => {
        Bungee.checkForBoundViolations(node, bungee, idx)
      })
    }
  }
}
