import { atom } from "jotai"
import { getAtomValue } from "./atomStore"

export const curSimInitTAtom = atom<number>(Date.now())

export const getCurSimElapsedTimeRn = () => {
  return (Date.now() - getAtomValue(curSimInitTAtom)) / 1000
}

export const simWidthMeters = atom<number>(0)
export const simHeightMeters = atom<number>(0)
