export namespace R2 {
  export interface Vector {
    x: number
    y: number
  }

  export const newVector = (x: number, y: number): Vector => {
    return { x, y }
  }
  export const sub = (v2: Vector, v1: Vector) => {
    return newVector(v2.x - v1.x, v2.y - v1.y)
  }
  export const add = (v2: Vector, v1: Vector) => {
    return newVector(v1.x + v2.x, v1.y + v2.y)
  }
  export const mul = (v: Vector, n: number) => {
    return newVector(n * v.x, n * v.y)
  }
  export const mag = (v: Vector) => {
    return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2))
  }
  export const norm = (v: Vector) => {
    const len = mag(v)
    const norm: Vector = newVector(
      len === 0 ? 0 : v.x / len,
      len === 0 ? 1 : v.y / len,
    )
    return {
      norm,
      mag: len,
    }
  }
  // NOTE: Removed a normalization that was in here b/c it was wrong to do that lol
  export const dot = (v1: Vector, v2: Vector) => {
    const v1Norm = v1
    const v2Norm = v2
    return v1Norm.x * v2Norm.x + v1Norm.y * v2Norm.y
  }
  export const cross = (v1: Vector, v2: Vector) => {
    const jointMag = mag(v1) * mag(v2)
    const dotProd = dot(v1, v2)
    return Math.acos(dotProd / jointMag)
  }
  export const reverse = (v: Vector) => {
    return newVector(v.x * -1, v.y * -1)
  }
  export const copy = (v: Vector) => {
    return newVector(v.x, v.y)
  }
  export const orthogonal = (v: Vector) => {
    return newVector(v.y, v.x)
  }
  export const distance = (v1: Vector, v2: Vector) => {
    return Math.sqrt(Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2))
  }
  export const rotate2D = (center: Vector, angle: number, points: Vector[]) => {
    // For each point...
    let newPoints: Vector[] = []
    for (let i = 0; i < points.length; i++) {
      // Move rot center to origin
      let newPoint = copy(points[i])
      newPoint = sub(newPoint, center)
      // Rotate
      newPoint = newVector(
        newPoint.x * Math.cos(angle) - newPoint.y * Math.sin(angle),
        newPoint.y * Math.cos(angle) + newPoint.x * Math.sin(angle),
      )
      // Move out of centering
      newPoint = add(newPoint, center)
      newPoints.push(newPoint)
    }
    return newPoints
  }
  export const interpBetweenPoints = (p1: Vector, p2: Vector, t: number) => {
    let delta = copy(p2)
    delta = sub(delta, p1)
    delta = mul(delta, t)
    let result = add(p1, delta)
    return result
  }
  export const findPointsCenter = (points: Vector[]) => {
    const runningAvg = newVector(0, 0)
    for (let point of points) {
      runningAvg.x += point.x
      runningAvg.y += point.y
    }
    runningAvg.x /= points.length
    runningAvg.y /= points.length
    return runningAvg
  }
  export const fromNormAndMag = (normal: Vector, mag: number) => {
    // Normalize
    const n = norm(normal).norm
    const output = mul(n, mag)
    return output
  }
}
