import { atom } from "jotai"
import { PlayableNote } from "../types/audio/note"

interface MidiState {
  shown: boolean
  mode: "note" | "major-chord"
}

export const midiSettingAtom = atom<MidiState>({ mode: "note", shown: false })

/**
 * This atom is sort of a FigureHead.
 * The actual synths control the notes that actually play.
 * This tells the UI what to render tho, since the synth internal state is not exposed to React.
 * TODO: Maybe consolidate those.
 */
export const currentlyPlayingNotesAtom = atom<PlayableNote[]>([])
