import { useAtomValue } from "jotai"
import "./SettingsSidebar.css"
import { isSettingsPanelOpenAtom } from "../../state/settings_sidebar"
import React from "react"

/**
 * Component that conditionally renders some nice UI controls for whatever instrument is currnetly in use.
 *
 * NOTES
 * structurally... i almost feel like this should be something that PAGES are responsible for rendering. And they can add chilren to it.
 * That feels like something better than the header.
 *
 * If the page doens't need it; it won't render it. Then the contents can be well owned, but pull on shared components.
 */
export const SettingsSidebar = (props: React.PropsWithChildren) => {
  const isSettingsSidebarOpen = useAtomValue(isSettingsPanelOpenAtom)

  return (
    <div
      className={
        "settingsSidebar" +
        (isSettingsSidebarOpen ? " settingsSidebarOpen" : "")
      }
    >
      {props.children}
    </div>
  )
}
