import { getAtomValue } from "../../../state/atomStore"
import { activeSampleAtom } from "../../../state/sampleState"
import { PlayableNote } from "../../../types/audio/note"
import { audioCoreAtom } from "../core/AudioCore"
import { NodeRepr_t } from "@elemaudio/core"

export interface SynthVoice {
  note: PlayableNote
}

/**
 * The point of this class is to be a high-level controller of synthesis.
 *
 * You should be able to override this, and it'll handle all the logic for you around playback things, etc.
 */

export class AyisenSynth {
  // Constructor
  AyisenSynth() {}

  // The synth instance is the OWNER of a set of voices that are currently playing.
  // This "currently playing" logic exists EXPLICITLY OUTSIDE OF REACT STATE.
  // - Web render doesn't generally care about changes to this.
  // - We want IMMEDIATE access to this value, to dispatch sonic changes right away.
  // Any other state that may effect sound is synced through atoms so web can see it too :^)
  voices: SynthVoice[] = []

  // Public facing adapters
  public triggerNotes(notes: PlayableNote[]) {
    // TODO: Fix. Should clear notes on key up operation, but react nodes being buggy
    this.voices = []

    const noteNames = [...notes]

    // remove any pre-existing
    this.voices = this.voices.filter((voice) => !noteNames.includes(voice.note)) // todo: better typing to enforce note id

    // re-add (todo: may need some type of uuid for this specific insert if replaced)
    for (let note of notes) this.voices.push({ note: note })

    this.rerenderAudioFromState()
  }
  public releaseNotse(notes: PlayableNote[]) {
    const noteNames = [...notes]

    // remove the note from voices
    this.voices = this.voices.filter((voice) => !noteNames.includes(voice.note)) // todo: better typing to enforce note id

    this.rerenderAudioFromState()
  }

  rerenderAudioFromState() {
    // override: render from state
  }

  getAudioCore() {
    return getAtomValue(audioCoreAtom)
  }

  renderAudioNodes(nodes: number | NodeRepr_t) {
    const audioCore = this.getAudioCore()
    if (audioCore) {
      audioCore.core?.render(nodes)
      return nodes
    }
  }

  getActiveSample() {
    const sampleNameToPick = getAtomValue(activeSampleAtom)
    if (!sampleNameToPick) return undefined

    const audioCore = this.getAudioCore()

    const chosen =
      audioCore.sampleHandler?.loadedKits["Demo Kit (todo)"][sampleNameToPick]

    return chosen
  }
}
