import FidgetUtils from "../../utilities"
import Fidget, {
  FidgetConfig,
  PointEvent,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"
import Fidget3D from "../../Engines/VisualEngine/Fidget/Fidget3D"

import * as THREE from "three"
import Random from "../../libs/Math/Random"
import { FidgetId } from "../../constants/fidgetConsts"

// JS Library for Ripple Animations

export default class Furball extends Fidget3D {
  // Class members
  edgeConfigs: {
    width: number
    color: THREE.ColorRepresentation
    rotationRate: number
  }[]
  lineSegs: THREE.LineSegments[]
  sphere: THREE.Mesh

  constructor(canvas: HTMLCanvasElement, config: FidgetConfig = {}) {
    // Normal fidget constructor
    super(canvas, FidgetId.HADRON, config)

    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.MeshBasicMaterial({
      color: "rgb(100, 200, 255)",
      opacity: 0.5,
    })
    const cube = new THREE.Mesh(geometry, material)
    // scene.add( cube );

    this.edgeConfigs = [
      {
        width: 1,
        color: "red",
        rotationRate: 0.015,
      },
      {
        width: 1,
        color: "white",
        rotationRate: -0.015,
      },
      {
        width: 2,
        color: "purple",
        rotationRate: -0.01,
      },
      {
        width: 2,
        color: "green",
        rotationRate: +0.01,
      },
    ]

    this.lineSegs = this.edgeConfigs.map((config) => {
      // const edges = new THREE.EdgesGeometry( new THREE.BoxGeometry(config.width, config.width, config.width) );
      const edges = new THREE.EdgesGeometry(
        new THREE.DodecahedronGeometry(config.width),
      )
      const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: config.color, linewidth: 500 }),
      )
      this.scene.add(line)
      return line
    })

    const sphereGeo = new THREE.SphereGeometry(0.5)
    const sphereMat = new THREE.MeshBasicMaterial({
      color: "orange",
      opacity: 0.1,
    })
    this.sphere = new THREE.Mesh(sphereGeo, sphereMat)
    this.sphere.position.z = 2
    this.scene.add(this.sphere)

    const geometry2 = new THREE.PlaneGeometry(5, 5)
    const material2 = new THREE.MeshBasicMaterial({ color: "blue" })
    const floor = new THREE.Mesh(geometry2, material2)
    // scene.add ( floor );

    cube.position.z = 2
    for (let line of this.lineSegs) {
      line.position.z = 2
    }

    this.camera.position.z = 5
  }

  // Bubble management
  addHair() {
    const chaos = false

    const width = chaos
      ? Random.getRandomFloat(1, 2)
      : Random.randomChoice([1, 2]) // 2
    const color: THREE.Color = Random.randomThreeColor()
    const rotationRate = chaos
      ? Random.getRandomFloat(-0.2, 0.2)
      : Random.randomChoice([0.01]) // 0.01

    this.edgeConfigs.push({
      width: width,
      color: color,
      rotationRate: rotationRate,
    })
    const edges = new THREE.EdgesGeometry(new THREE.DodecahedronGeometry(width))
    const line = new THREE.LineSegments(
      edges,
      new THREE.LineBasicMaterial({ color, linewidth: 500 }),
    )
    line.position.z = 2
    this.scene.add(line)
    this.lineSegs.push(line)

    this.sphere.material = new THREE.MeshBasicMaterial({
      color: Random.randomThreeColor(),
      opacity: 0.1,
    })
    for (let line of this.lineSegs) {
      line.material = new THREE.LineBasicMaterial({
        color: Random.randomThreeColor(),
      })
    }
  }

  onDrag = (e: PointEvent) => {}

  onClick = (e: PointEvent) => {
    // Add bubble
    this.addHair()
  }

  // Render Frame
  renderFrame = async () => {
    for (let lineIdx = 0; lineIdx < this.lineSegs.length; lineIdx++) {
      this.lineSegs[lineIdx].rotation.x +=
        this.edgeConfigs[lineIdx].rotationRate
      this.lineSegs[lineIdx].rotation.y +=
        this.edgeConfigs[lineIdx].rotationRate
    }

    this.camera.rotateOnAxis(new THREE.Vector3(0, 0, 1), 0.001)
    this.renderer.render(this.scene, this.camera)
  }
}
