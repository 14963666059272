import FidgetUtils from "../../utilities"
import Fidget, {
  FidgetConfig,
  PointEvent,
  SharedStateEntity,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { GraphConnectionMap, GraphNodeMap } from "../../libs/Math/Graph"
import { Bungee } from "../../libs/Math/BungeeNode"
import Random from "../../libs/Math/Random"
import { R2 } from "../../libs/Math/R2"
import { FidgetId } from "../../constants/fidgetConsts"
import { v4 as uuidv4 } from "uuid"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

const heart = [
  {
    x: 0.4401041666666667,
    y: 0.5015479876160991,
  },
  {
    x: 0.34479166666666666,
    y: 0.4086687306501548,
  },
  {
    x: 0.4979166666666667,
    y: 0.5686274509803921,
  },
  {
    x: 0.340625,
    y: 0.43550051599587203,
  },
  {
    x: 0.35,
    y: 0.391124871001032,
  },
  {
    x: 0.3572916666666667,
    y: 0.37874097007223945,
  },
  {
    x: 0.36614583333333334,
    y: 0.3632610939112487,
  },
  {
    x: 0.3734375,
    y: 0.35190918472652216,
  },
  {
    x: 0.38229166666666664,
    y: 0.34674922600619196,
  },
  {
    x: 0.3921875,
    y: 0.347781217750258,
  },
  {
    x: 0.4015625,
    y: 0.35294117647058826,
  },
  {
    x: 0.40989583333333335,
    y: 0.3632610939112487,
  },
  {
    x: 0.41770833333333335,
    y: 0.37564499484004127,
  },
  {
    x: 0.425,
    y: 0.3890608875128999,
  },
  {
    x: 0.43072916666666666,
    y: 0.40454076367389064,
  },
  {
    x: 0.434375,
    y: 0.42208462332301344,
  },
  {
    x: 0.440625,
    y: 0.40970072239422084,
  },
  {
    x: 0.44583333333333336,
    y: 0.3973168214654283,
  },
  {
    x: 0.45416666666666666,
    y: 0.38080495356037153,
  },
  {
    x: 0.46041666666666664,
    y: 0.36429308565531476,
  },
  {
    x: 0.46875,
    y: 0.35190918472652216,
  },
  {
    x: 0.47604166666666664,
    y: 0.34365325077399383,
  },
  {
    x: 0.4859375,
    y: 0.34674922600619196,
  },
  {
    x: 0.49322916666666666,
    y: 0.35190918472652216,
  },
  {
    x: 0.5,
    y: 0.36119711042311664,
  },
  {
    x: 0.5067708333333333,
    y: 0.3715170278637771,
  },
  {
    x: 0.5109375,
    y: 0.3942208462332301,
  },
  {
    x: 0.5182291666666666,
    y: 0.4055727554179567,
  },
  {
    x: 0.5192708333333333,
    y: 0.4200206398348813,
  },
  {
    x: 0.5197916666666667,
    y: 0.4375644994840041,
  },
  {
    x: 0.5213541666666667,
    y: 0.4540763673890609,
  },
  {
    x: 0.5182291666666666,
    y: 0.4726522187822497,
  },
  {
    x: 0.5161458333333333,
    y: 0.48606811145510836,
  },
  {
    x: 0.5130208333333334,
    y: 0.5067079463364293,
  },
  {
    x: 0.5104166666666666,
    y: 0.5159958720330238,
  },
  {
    x: 0.5072916666666667,
    y: 0.5314757481940144,
  },
  {
    x: 0.503125,
    y: 0.5510835913312694,
  },
  {
    x: 0.4901041666666667,
    y: 0.5882352941176471,
  },
  {
    x: 0.4864583333333333,
    y: 0.6016511867905057,
  },
  {
    x: 0.4796875,
    y: 0.6130030959752322,
  },
  {
    x: 0.475,
    y: 0.6222910216718266,
  },
  {
    x: 0.46927083333333336,
    y: 0.6388028895768834,
  },
  {
    x: 0.4630208333333333,
    y: 0.6542827657378741,
  },
  {
    x: 0.45677083333333335,
    y: 0.6676986584107327,
  },
  {
    x: 0.4484375,
    y: 0.6780185758513931,
  },
  {
    x: 0.4395833333333333,
    y: 0.6873065015479877,
  },
  {
    x: 0.4317708333333333,
    y: 0.6821465428276574,
  },
  {
    x: 0.425,
    y: 0.6749226006191951,
  },
  {
    x: 0.4166666666666667,
    y: 0.6646026831785345,
  },
  {
    x: 0.4114583333333333,
    y: 0.6491228070175439,
  },
  {
    x: 0.40520833333333334,
    y: 0.6367389060887513,
  },
  {
    x: 0.39791666666666664,
    y: 0.6202270381836945,
  },
  {
    x: 0.39166666666666666,
    y: 0.6068111455108359,
  },
  {
    x: 0.3828125,
    y: 0.5902992776057792,
  },
  {
    x: 0.37604166666666666,
    y: 0.5717234262125903,
  },
  {
    x: 0.37083333333333335,
    y: 0.5531475748194015,
  },
  {
    x: 0.36614583333333334,
    y: 0.5335397316821465,
  },
  {
    x: 0.35833333333333334,
    y: 0.5159958720330238,
  },
  {
    x: 0.3541666666666667,
    y: 0.4984520123839009,
  },
  {
    x: 0.3515625,
    y: 0.48297213622291024,
  },
  {
    x: 0.3494791666666667,
    y: 0.4695562435500516,
  },
  {
    x: 0.3453125,
    y: 0.4499484004127967,
  },
]

interface OsciState {
  centroidId: string
  oscillator: Bungee.Bungee
}

export default class OscillatorFidget extends Fidget {
  // ID of the main oscillator hub node

  activeBungee: Bungee.BungeeNode | undefined

  constructor(canvas: HTMLCanvasElement, config: FidgetConfig = {}) {
    super(canvas, FidgetId.OSCI, config)

    // Init nodes and connects
    this.initNodes()
    this.initConnections()

    const logo = document.getElementById("ayisen-logo")
    logo?.addEventListener("click", () => {
      this.initNodesBase()
    })

    // Set sync state
    // TODO @Marcel: Callback may not yet be established... might make sense to pass react cb into Sim constructor?
    this.sharedState.write(this.getDefaultOscil(), SharedStateEntity.FIDGET)
  }

  getDefaultOscil() {
    // Allocate centriod & oscillator
    const centroid: Bungee.BungeeNode = {
      loc: R2.newVector(0.5, 0.5),
      velocity: R2.newVector(0, 0),
      connections: [],
    }
    const centroidId = uuidv4()

    const oscillator = {
      nodes: { [centroidId]: centroid },
      color: Random.randomColor(),
    }

    const state: OsciState = {
      centroidId,
      oscillator,
    }

    return state
  }

  resetValues() {
    this.initNodesBase()
  }

  initRandomTree() {}

  getRandomBranches() {}

  initNodes() {}

  initNodesBase() {
    // Init triangle
    // this.bungees = Bungee.constuctRandomBungees();
    // let b1: Bungee.BungeeNode = {
    //     loc: R2.newVector(0.35, 0.35),
    //     velocity: R2.newVector(0, 0),
    //     connections: []
    // }
    // let b2: Bungee.BungeeNode = {
    //     loc: R2.newVector(0.5, 0.5),
    //     velocity: R2.newVector(0, 0),
    //     connections: []
    // }
    // let b3: Bungee.BungeeNode = {
    //     loc: R2.newVector(0.59, 0.70),
    //     velocity: R2.newVector(0, 0),
    //     connections: []
    // }
    // let b4: Bungee.BungeeNode = {
    //     loc: R2.newVector(0.29, 0.40),
    //     velocity: R2.newVector(0, 0),
    //     connections: []
    // }
    // b2.connections = [b1, b3, b4]
    // this.bungees = [{
    //     nodes: [
    //         b2, b1, b3, b4
    //     ],
    //     color: Random.randomColor()
    // }]
  }

  // addConnection(uid1: string, uid2: string) {

  //     AyisenMath.Graph.addConnection(uid1, uid2, this.connectionGraph);

  // }

  initConnections() {
    // this.connectionGraph = AyisenMath.Graph.generateRandomConnections(this.nodes);
  }

  onClick = (e: PointEvent) => {}

  mouseUp = (e: PointEvent) => {
    // TODO @Marcel: should handle on exit as well lol.

    if (this.activeBungee) this.activeBungee = undefined

    // Write back to the object to ensure React updates with any pending changes.
    // TODO: We're raw modifying the value during drag which is not great... but this should cover sync at the end.
    const state = this.sharedState.read() ?? this.getDefaultOscil()
    this.sharedState.write(state, SharedStateEntity.FIDGET)
  }

  onMouseDown = (e: PointEvent) => {
    const { oscillator, centroidId }: OsciState =
      this.sharedState.read() ?? this.getDefaultOscil()

    const cursor = R2.newVector(
      e.x / this.canvas.width,
      e.y / this.canvas.height,
    )
    for (let node of Object.values(oscillator.nodes)) {
      console.log(cursor, " vs ", node.loc)
      if (
        VisualEngine.canvas.isOverCircle(
          node.loc,
          16 / this.canvas.width,
          cursor,
        )
      ) {
        this.activeBungee = node
        console.log("Found!")
        break
      }
    }

    if (!this.activeBungee) {
      const newId = uuidv4()

      oscillator.nodes[newId] = {
        loc: cursor,
        velocity: R2.newVector(0, 0),
        connections: [],
      }

      oscillator.nodes[centroidId].connections.push(newId)
    }

    this.sharedState.write({ oscillator, centroidId }, SharedStateEntity.FIDGET)
  }

  onDrag = (e: PointEvent) => {
    if (this.activeBungee)
      this.activeBungee.loc = R2.newVector(
        e.x / this.canvas.width,
        e.y / this.canvas.height,
      )
  }

  // Animate frame
  renderFrame = async () => {
    if (!this.ctx) return

    // TODO @Marcel: Move this to the simulation properties!
    let smallScreen = false
    if (this.canvas.width < 500) {
      smallScreen = true
    }

    // Render / Update ripples

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    let timeElapsed = 0
    if (this.initT) {
      const now = new Date()

      timeElapsed = now.getTime() - this.initT
    }

    const { oscillator, centroidId }: OsciState =
      this.sharedState.read() ?? this.getDefaultOscil()

    Object.values(oscillator.nodes).forEach((node, idx) => {
      if (!this.ctx || !this.lastRenderT) return

      VisualEngine.canvas.drawBubble(
        this.ctx,
        R2.newVector(
          node.loc.x * this.canvas.width,
          node.loc.y * this.canvas.height,
        ),
        12,
        oscillator.color,
        1,
      )

      // Draw line to siblings
      for (let connection of Bungee.getHydratedConnections(node, oscillator)) {
        VisualEngine.canvas.drawLine(
          this.ctx,
          R2.newVector(
            node.loc.x * this.canvas.width,
            node.loc.y * this.canvas.height,
          ),
          R2.newVector(
            connection.loc.x * this.canvas.width,
            connection.loc.y * this.canvas.height,
          ),
          4,
          oscillator.color,
        )
        const loc1 = R2.newVector(
          node.loc.x * this.canvas.width,
          node.loc.y * this.canvas.height,
        )
        const loc2 = R2.newVector(
          connection.loc.x * this.canvas.width,
          connection.loc.y * this.canvas.height,
        )
        let interpVal =
          0.5 * (2 + Math.sin((2 * Math.PI * 0.5 * timeElapsed) / 1000))
        const chosen = R2.interpBetweenPoints(loc1, loc2, interpVal)

        VisualEngine.canvas.drawBubble(
          this.ctx,
          chosen,
          10,
          oscillator.color,
          1,
        )
      }

      // Store the initial location
      node.prevLoc = { ...node.loc }

      const newBody = AyisenMath.Body.updateBodyLoc(
        { location: node.loc, velocity: node.velocity, width: 0 },
        this.lastRenderT,
        0.0,
        0.0,
      )
      node.loc = newBody.location
      node.velocity = newBody.velocity

      // TODO @marcel: If the connections of a given oscillator are going to cross their angles when drawn out from the given vertex... Bounce them off angularly!!!
      // This will lead to really cool preservation of the shape's interior!!! :)

      // TODO @Marcel: Potentially have some tension running through these, so they spring together / appart when they get too close / far!

      // TODO: Add interactability (click to add? drag to create a new ring of nodes that connect up on release? (second might be really neat)).
    })

    // Parity check / bounce
    // Object.values(oscillator.nodes).forEach((node, idx) => {
    //     Bungee.checkForAngleParityViolation(node);
    // })
    Object.values(oscillator.nodes).forEach((node, idx) => {
      Bungee.checkForBoundViolations(node, oscillator, idx)
    })
  }
}
