// export enum PlayableNoteEnum {
//     C0, "C0#", D0, "D0#", E0, F0, "F0#", G0, "G0#", A0, "A0#", B0,
//     C1, "C1#", D1, "D1#", E1, F1, "F1#", G1, "G1#", A1, "A1#", B1,
//     C2, "C2#", D2, "D2#", E2, F2, "F2#", G2, "G2#", A2, "A2#", B2,
//     C3, "C3#", D3, "D3#", E3, F3, "F3#", G3, "G3#", A3, "A3#", B3,
//     C4, "C4#", D4, "D4#", E4, F4, "F4#", G4, "G4#", A4, "A4#", B4,
//     C5, "C5#", D5, "D5#", E5, F5, "F5#", G5, "G5#", A5, "A5#", B5,
//     C6, "C6#", D6, "D6#", E6, F6, "F6#", G6, "G6#", A6, "A6#", B6,
//     C7, "C7#", D7, "D7#", E7, F7, "F7#", G7, "G7#", A7, "A7#", B7,
//     C8, "C8#", D8, "D8#", E8, F8, "F8#", G8, "G8#", A8, "A8#", B8,
// }

export const PlayableNoteList = [
  "C0",
  "C0#",
  "D0",
  "D0#",
  "E0",
  "F0",
  "F0#",
  "G0",
  "G0#",
  "A0",
  "A0#",
  "B0",
  "C1",
  "C1#",
  "D1",
  "D1#",
  "E1",
  "F1",
  "F1#",
  "G1",
  "G1#",
  "A1",
  "A1#",
  "B1",
  "C2",
  "C2#",
  "D2",
  "D2#",
  "E2",
  "F2",
  "F2#",
  "G2",
  "G2#",
  "A2",
  "A2#",
  "B2",
  "C3",
  "C3#",
  "D3",
  "D3#",
  "E3",
  "F3",
  "F3#",
  "G3",
  "G3#",
  "A3",
  "A3#",
  "B3",
  "C4",
  "C4#",
  "D4",
  "D4#",
  "E4",
  "F4",
  "F4#",
  "G4",
  "G4#",
  "A4",
  "A4#",
  "B4",
  "C5",
  "C5#",
  "D5",
  "D5#",
  "E5",
  "F5",
  "F5#",
  "G5",
  "G5#",
  "A5",
  "A5#",
  "B5",
  "C6",
  "C6#",
  "D6",
  "D6#",
  "E6",
  "F6",
  "F6#",
  "G6",
  "G6#",
  "A6",
  "A6#",
  "B6",
  "C7",
  "C7#",
  "D7",
  "D7#",
  "E7",
  "F7",
  "F7#",
  "G7",
  "G7#",
  "A7",
  "A7#",
  "B7",
  "C8",
  "C8#",
  "D8",
  "D8#",
  "E8",
  "F8",
  "F8#",
  "G8",
  "G8#",
  "A8",
  "A8#",
  "B8",
] as const

export type PlayableNote = (typeof PlayableNoteList)[number]

// export type PlayableNote =
//     "C0"| "C0#"| "D0"| "D0#"| "E0"| "F0"| "F0#"| "G0"| "G0#"| "A0"| "A0#"| "B0"|
//     "C1"| "C1#"| "D1"| "D1#"| "E1"| "F1"| "F1#"| "G1"| "G1#"| "A1"| "A1#"| "B1"|
//     "C2"| "C2#"| "D2"| "D2#"| "E2"| "F2"| "F2#"| "G2"| "G2#"| "A2"| "A2#"| "B2"|
//     "C3"| "C3#"| "D3"| "D3#"| "E3"| "F3"| "F3#"| "G3"| "G3#"| "A3"| "A3#"| "B3"|
//     "C4"| "C4#"| "D4"| "D4#"| "E4"| "F4"| "F4#"| "G4"| "G4#"| "A4"| "A4#"| "B4"|
//     "C5"| "C5#"| "D5"| "D5#"| "E5"| "F5"| "F5#"| "G5"| "G5#"| "A5"| "A5#"| "B5"|
//     "C6"| "C6#"| "D6"| "D6#"| "E6"| "F6"| "F6#"| "G6"| "G6#"| "A6"| "A6#"| "B6"|
//     "C7"| "C7#"| "D7"| "D7#"| "E7"| "F7"| "F7#"| "G7"| "G7#"| "A7"| "A7#"| "B7"|
//     "C8"| "C8#"| "D8"| "D8#"| "E8"| "F8"| "F8#"| "G8"| "G8#"| "A8"| "A8#"| "B8"

export function semitonesBetweenNotes(
  baseNote: PlayableNote,
  targetNote: PlayableNote,
) {
  const baseIdx = PlayableNoteList.findIndex((e) => e === baseNote)
  const targetIdx = PlayableNoteList.findIndex((e) => e === targetNote)

  if (baseIdx === -1 || targetIdx === -1) return null

  return targetIdx - baseIdx
}

export function getNoteIntervalUp(note: PlayableNote, interval: number) {
  const noteIdx = PlayableNoteList.findIndex((e) => e === note)
  if (noteIdx === -1) return null

  const newNote = PlayableNoteList[noteIdx + interval]
  if (!newNote) return null

  return newNote
}

type NoteInfoMap2 = {
  [key in PlayableNote]?: number
}

// Cached note info mapping
let NOTE_INFO_MAP: NoteInfoMap2 | undefined = undefined
export const getNoteInfo: () => NoteInfoMap2 = () => {
  if (NOTE_INFO_MAP !== undefined) return NOTE_INFO_MAP

  const c0Freq = 16.3516
  let noteNames: NoteInfoMap2 = {}

  let idx = 0
  for (let noteName of PlayableNoteList) {
    const freq = c0Freq * Math.pow(2, idx / 12)
    noteNames[noteName] = freq
    idx += 1
  }

  NOTE_INFO_MAP = noteNames

  return noteNames
}
