import React from "react"
import { useAtomValue } from "jotai"
import { AyisenSynth } from "../../Engines/AudioEngine/AyisenSynth/AyisenSynth"
import VisualEngine from "../../Engines/VisualEngine"
import SimCanvas from "./SimCanvas"
import { midiSettingAtom } from "../../state/midi_keyboard_state"
import { FidgetId } from "../../constants/fidgetConsts"
import MidiKeyboard from "../Keyboards/MidiKeyboard"

interface SimInstrumentProps {
  // Synth is passed down so the parent can control it
  synth: AyisenSynth
  fidgetId?: FidgetId
  createNewSimulator?: (canvas: HTMLCanvasElement) => any
}

/**
 * SimInstrument is a base class for building instruments that consist of
 * - Playable Keyboard
 * - Some visual simulation going on
 * - Some other UI Renderable
 */
export function SimInstrument({
  synth,
  createNewSimulator,
  children,
  fidgetId = FidgetId.FISSION,
}: React.PropsWithChildren<SimInstrumentProps>) {
  const midiSettings = useAtomValue(midiSettingAtom)

  return (
    <>
      <SimCanvas
        attachNewSimulator={
          // attach a simulator if it's provided
          createNewSimulator
            ? createNewSimulator
            : // otherwise; default to a blank fidget
              (canvas: HTMLCanvasElement) => {
                return new VisualEngine.Fidget(canvas, fidgetId)
              }
        }
        key={1209287509275}
        fidgetState={{}}
        setFidgetState={() => {}}
      />

      {/* Absolute position so that children can sit over the canvas... kinda hacky but ok for now */}
      {/* Main issue; this absorbs clicks from the Canvas! */}
      {/* So, we can't render it full size all the time, or the canvas is a lost cause. */}
      <div
        style={{
          top: 0,
          left: 0,
          // width: '100%',
          // height: '100%',
          position: "absolute",
          zIndex: 100,
        }}
      >
        {children}
      </div>

      {midiSettings.shown && <MidiKeyboard synth={synth ?? undefined} />}
    </>
  )
}
