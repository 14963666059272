import ela from "../../Engines/AudioEngine/ela/ela"
import { AyisenSynth } from "../../Engines/AudioEngine/AyisenSynth/AyisenSynth"
import { getAtomValue } from "../../state/atomStore"
import { samplePathAtom } from "../../state/sampleState"
import {
  PlayableNote,
  getNoteInfo,
  semitonesBetweenNotes,
} from "../../types/audio/note"
import { el, NodeRepr_t } from "@elemaudio/core"
import { BouncingBallModel } from "../../Models/bouncingBall"
import { getCurSimElapsedTimeRn } from "../../state/sim"
import { audioCoreAtom } from "../../Engines/AudioEngine/core/AudioCore"
import { generateUUID } from "three/src/math/MathUtils"
import { startHeightAtom } from "../Bounce/state"

export class SineSynth extends AyisenSynth {
  rerenderAudioFromState() {
    const noteToFreq = getNoteInfo()

    // The time elapsed when we're releasing these audio computers....
    // This will offset the time computation done in the audio thread.
    // Should provide soooommee semblance of syncing. Not enough, but an okay start hopefully.
    // This value needs to be in sync with what the visual thread THINKS is the current elapsed time when we release the audio node.
    const elapsedTimeToStartAudioSimsWith = getCurSimElapsedTimeRn()
    console.warn("Elapsed Time Buffering by", elapsedTimeToStartAudioSimsWith)

    if (this.voices.length === 0) {
      this.renderAudioNodes(el.const({ value: 0 }))
      return
    }

    function sineTone(t: number | NodeRepr_t) {
      return el.sin(el.mul(2 * Math.PI, t))
    }

    const renderable = el.add(
      ...this.voices.map((voice) => {
        const freq = noteToFreq[voice.note] ?? 440
        return sineTone(el.phasor(freq, 0)) // TODO: Better behavior; don't just default to 440!
      }),
    )

    const height = getAtomValue(startHeightAtom)
    let bouncingMixIn = el.div(
      BouncingBallModel.getModel(height).newAudioComputer(
        elapsedTimeToStartAudioSimsWith,
      ),
      height, // normalize
    )

    // Commented out meter & print statements are very helpful for debugging trickiness in audio-visual syncing.
    // const debugMeter = el.meter(
    //   { key: generateUUID() },
    //   el.add(
    //     el.const({ value: elapsedTimeToStartAudioSimsWith }),
    //     el.div(el.counter({ key: generateUUID() }, 1), el.sr()),
    //   ),
    // )

    // Render!
    this.renderAudioNodes(el.add(el.mul(renderable, bouncingMixIn)))

    // const audioCore = getAtomValue(audioCoreAtom)
    // if (audioCore && audioCore.core)
    //   audioCore.core.on("meter", (e) => {
    //     console.warn("Meter reading: ", e.max)
    //   })

    return renderable
  }
}
