import Fidget, {
  PointEvent,
  SharedStateEntity,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"
import { Geometry, SmartGeo, tesselateGeometry } from "../../libs/Math/Geometry"
import Random from "../../libs/Math/Random"
import { MotableDelta } from "../../libs/Math/Motion"
import { keToV } from "../../libs/Math/Body"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

export interface Bubble {
  breathPeriod: number

  maxRad: number

  initial: {
    t: number
  }

  current: {
    location: R2.Vector
    radius: number
    t: number
    color: string
  }

  // Running Vars
  radius?: number
}

export interface FullGeo {
  geo: Geometry

  color: string

  motableDelta?: MotableDelta
}

export interface Strive {
  indices: number[]

  target: R2.Vector

  pullPeriodMS: number
  reachingPeriodMS: number
  pausingPeriodMS: number

  peakKEPerSec: number
}

export interface AmoebaBody {
  // Outline of current "body" in clockwise order
  bodyPoints: SmartGeo

  color: string

  // Current motion?
  // Linked list with body points.... represets kinetic energy along each axis....kinda funky but it is what it is lol
  energy: Geometry

  strive?: Strive

  // Set of indices currently in the air, striving (these cannot act on other indices via tension)
  raisedIndices?: number[]

  lockedIndices?: number[]
}

interface Intersection {
  line1: {
    p1: number
    p2: number
  }
  line2: {
    p1: number
    p2: number
  }
}
interface Intersections {
  [key: number]: Intersection
}

class AmoebaObj {
  body: AmoebaBody

  private inCreation: boolean

  constructor(body: AmoebaBody, inCreation = false) {
    this.body = body

    this.inCreation = inCreation
  }

  isInCreation() {
    return this.inCreation
  }

  completeCreation() {
    // TODO: Smooth clusters of points

    // Gonna iterate over the points, and
    // - Avg things close together out.
    // Look through for things to group together
    let runGroups: number[][] = [[0]]

    const RUN_THRESH = 16
    const MAX_RUN_LENGTH = 15

    for (let i = 1; i < this.body.bodyPoints.points.length; i++) {
      // Grab anything ahead or behidn that's close and we can avg.
      const activeRunGroup = runGroups[runGroups.length - 1]
      const lastRunGroupPos =
        this.body.bodyPoints.points[activeRunGroup[activeRunGroup.length - 1]]

      // Dist
      if (
        activeRunGroup.length < MAX_RUN_LENGTH &&
        R2.distance(lastRunGroupPos, this.body.bodyPoints.points[i]) <
          RUN_THRESH
      ) {
        activeRunGroup.push(i)
      } else {
        runGroups.push([i])
      }
    }

    // Found some runs? (must be at least 3, can't dimmension flop lol)
    if (
      runGroups.length >= 3 &&
      runGroups.find((runGroup) => runGroup.length > 1)
    ) {
      console.log("Run Groups: ", runGroups)

      // Time to do a consolidation step.
      // Need to merge points together, fun fun.
      let newPoints: Geometry = []

      for (let runGroup of runGroups) {
        // Merge all points here into one
        let avg = R2.newVector(0, 0)
        for (let idx of runGroup) {
          avg = R2.add(this.body.bodyPoints.points[idx], avg)
        }

        // Finish avg
        avg = R2.mul(avg, 1 / runGroup.length)

        newPoints.push(avg)
      }

      this.body.bodyPoints.points = newPoints
    }

    // Add ambient energy
    this.body.energy = this.body.bodyPoints.points.map((point) => {
      return R2.newVector(
        Random.getRandomFloat(-300, 300),
        Random.getRandomFloat(-300, 300),
      )
    })

    this.inCreation = false
    return
  }

  giveIndicesAgenda(indices: number[], target: R2.Vector, energy: number) {
    // Update theh energy on a set of indices to give them an agenda.

    // TODO: Do this smoothly over a little time to make no jolt.

    for (let i of indices) {
      const trajectoryToTarget = R2.sub(target, this.body.bodyPoints.points[i])

      this.body.energy[i] = R2.mul(R2.norm(trajectoryToTarget).norm, energy)
    }

    console.log("Energy New: ", this.body.energy)
  }

  strive(timeSinceStartMS: number, dtSeconds: number) {
    if (!this.body.strive) {
      this.body.raisedIndices = []
      return
    }

    const cyclePeriodMS =
      this.body.strive.reachingPeriodMS + this.body.strive.pausingPeriodMS
    const curCycleAct = timeSinceStartMS % cyclePeriodMS

    if (curCycleAct < this.body.strive.reachingPeriodMS) {
      // We're in the reach action
      this.body.raisedIndices = this.body.strive.indices
      this.body.lockedIndices = []

      // Insert some KE into the system
      let striveKEAddition = this.body.strive.peakKEPerSec / dtSeconds
      this.giveIndicesAgenda(
        this.body.strive.indices,
        this.body.strive.target,
        striveKEAddition,
      )

      // TODO: Ease down to 0 KE period in the system towards the end of the cycle.
    } else {
      // ... we're in the pull action
      this.body.raisedIndices = []
      this.body.lockedIndices = this.body.strive.indices

      for (let i of this.body.strive.indices) {
        this.body.energy[i] = R2.newVector(0, 0)
      }
    }

    // // Sin wave of effort (simulating pulling)
    // const striveModulator = (Math.sin(this.body.strive.pullPeriodMS / 1000 * timeSinceStartMS) + 1) / 2

    // if (striveModulator > 0.2) {
    //     this.body.raisedIndices = this.body.strive.indices
    //     this.body.lockedIndices = []
    // }
    // else {
    //     this.body.raisedIndices = []
    //     this.body.lockedIndices = this.body.strive.indices
    // }

    // let striveKEAddition = striveModulator * this.body.strive.peakKEPerSec / dtSeconds

    // this.giveIndicesAgenda(this.body.strive.indices, this.body.strive.target, striveKEAddition)
  }

  updatePositions(dtSeconds: number) {
    const getPointAngles = (geo: Geometry) => {
      const cachedAngles = geo.map((point, idx) => {
        const lastPointIdx = idx === 0 ? geo.length - 1 : idx - 1
        const nextPointIdx = (idx + 1) % geo.length

        let lastPoint = geo[lastPointIdx]
        let nextPoint = geo[nextPointIdx]

        const v1 = R2.sub(lastPoint, point)
        const v2 = R2.sub(nextPoint, point)

        const multMag = R2.mag(v1) * R2.mag(v2)

        const angle = Math.acos(R2.dot(v1, v2) / multMag)
        // console.log("mag: ", multMag)
        // console.log("Angle: ", angle)

        return angle
      })

      return cachedAngles
    }

    // Cache Parity on each point
    const initialAngles = getPointAngles(this.body.bodyPoints.points)

    // Move the points based on energy
    this.body.bodyPoints.points = this.body.bodyPoints.points.map(
      (point, idx) => {
        // Energy not found
        if (this.body.energy.length <= idx) return point

        // We need to administer a KE to modify the position.
        // The KE is directed.... so there's a velocity associated with it....
        // This might be not the right way to track this....
        // If we track velocity at all the time... we'd be modifying that over time.
        // Velocity is a "derived" prop though.... Energy is inherent state.
        // Hmmmm feels wrong to not have Bodies in energy space....

        // Enery to v is helpful...
        const velocity = R2.newVector(
          keToV(this.body.energy[idx].x, 1),
          keToV(this.body.energy[idx].y, 1),
        )

        const displacement = R2.mul(velocity, dtSeconds)
        const distTraveled = R2.mag(displacement)

        // Tension force.... defined by dist & elasticity
        // Lets say... force is directly proportional to distance.

        const lastPointIdx =
          idx === 0 ? this.body.bodyPoints.points.length - 1 : idx - 1
        const nextPointIdx = (idx + 1) % this.body.bodyPoints.points.length

        let lastPoint = this.body.bodyPoints.points[lastPointIdx]
        let nextPoint = this.body.bodyPoints.points[nextPointIdx]
        // console.log("Lst: ", lastPoint, "Next: ", nextPoint)
        // console.log("Disp: ", displacement)

        // Tension along the last point's arm
        const getWorkDueToTension = (
          primaryPoint: R2.Vector,
          secondaryPoint: R2.Vector,
          displacement: number,
        ) => {
          // Compute the normal along which the tension is acting.
          // Spoiler; It's pulling the primary point -> secondary point :-)
          const { norm: tensionNorm, mag: chordLen } = R2.norm(
            R2.sub(secondaryPoint, primaryPoint),
          )

          const tensionForce = Math.pow(chordLen / 20, 1.22) // TODO: some const
          const workDone = tensionForce * displacement

          // Now we have the AMOUNT of work done. Lets convert that into a vector, so it can be applied as an energy diff
          const workVector = R2.mul(tensionNorm, workDone)

          return workVector
        }

        // Modify the energy :-)
        // This is interesting... b/c we're going to need to do this operation by modifying the energy ALONG the tension axis.

        // So.... we'll need to declare an energy space vector.... do some adding...
        const workVector = R2.add(
          this.body.raisedIndices?.includes(lastPointIdx)
            ? R2.newVector(0, 0)
            : getWorkDueToTension(point, lastPoint, distTraveled),
          this.body.raisedIndices?.includes(nextPointIdx)
            ? R2.newVector(0, 0)
            : getWorkDueToTension(point, nextPoint, distTraveled),
        )
        // console.log("Work Vector", workVector)

        if (
          !this.body.lockedIndices ||
          !this.body.lockedIndices?.includes(idx)
        ) {
          this.body.energy[idx] = R2.add(this.body.energy[idx], workVector)
        }

        // console.log("Vel: ", velocity)
        // console.log("Scaled v: ", R2.mul(velocity, dtSeconds))

        return R2.add(point, displacement)
      },
    )

    // Push any shrinking angles outwards
    const anglesAfter = getPointAngles(this.body.bodyPoints.points)

    // for (let i=0; i<this.body.bodyPoints.points.length; i++) {

    //     const prevAngle = initialAngles[i]
    //     const afterAngle = anglesAfter[i]

    //     // console.log("Angle: ", prevAngle, ' -> ', afterAngle)

    //     if (afterAngle < Math.PI/4) {

    //         console.warn("Angle shrink detected!!!")

    //         // We need to push this angle out a bit.

    //         // The point before & after need to be given a little nudge orthogonal to their point->point

    //         const lastPointIdx = (i === 0) ? this.body.bodyPoints.points.length-1 :i-1
    //         const nextPointIdx = (i+1) % this.body.bodyPoints.points.length

    //         let lastPoint = this.body.bodyPoints.points[lastPointIdx]
    //         let nextPoint = this.body.bodyPoints.points[nextPointIdx]

    //         // Before one first
    //         let lastPointLine = R2.sub(lastPoint, this.body.bodyPoints.points[i])
    //         lastPointLine = R2.norm(R2.orthogonal(lastPointLine)).norm
    //         this.body.energy[lastPointIdx] = R2.add(this.body.energy[lastPointIdx], R2.mul(lastPointLine, 20))

    //         let nextPointLine = R2.sub(nextPoint, this.body.bodyPoints.points[i])
    //         nextPointLine = R2.norm(R2.orthogonal(nextPointLine)).norm
    //         this.body.energy[nextPointIdx] = R2.add(this.body.energy[nextPointIdx], R2.mul(nextPointLine, 20))

    //     }

    // }

    // TODO @Marcel: Merge points smootly when there is overlap.
    // - 2 lines are intersecting.
    // For every point;;

    // interface IndexSwap {
    //     l1p1Idx: number,
    //     l1p2Idx: number,
    //     l2p1Idx: number,
    //     l2p2Idx: number,
    // }

    // interface CrossConfig {
    //     sameSideOfJunctureSibling: number,
    //     acrossJuncture: {
    //         target: number,
    //         crossMaker: number
    //     }
    // }
    // const intersectionMapping: {[key: number]: CrossConfig} = {}

    // // Index -> where you should jump to instead
    // interface SwapMap {[key: number]: {firstPt: number, lastPt: number}}
    // let swapMap: SwapMap = {}

    // let foundSwapsLastRun = true

    // while(foundSwapsLastRun === true) {

    //     const swapsNeeded: IndexSwap[] = []

    //     for (let pointIdx = 0 ; pointIdx < this.body.bodyPoints.points.length; pointIdx ++) {

    //         // Check for overlap with everything else (-:
    //         const mainPointIdx = pointIdx

    //         // Already in the mix -> skip queueing for swap
    //         // Ignoring for now; this will act as a "map" for what to do
    //         // if (swapsNeeded.find(e => [e.l1p1Idx, e.l1p2Idx, e.l2p1Idx, e.l2p2Idx].includes(mainPointIdx)))
    //         //     continue;

    //         const mainPointNextIdx = (mainPointIdx+1) % this.body.bodyPoints.points.length
    //         const lineOfRelevance = new AyisenMath.Line.Line.PointLine(this.body.bodyPoints.points[mainPointIdx], this.body.bodyPoints.points[mainPointNextIdx])

    //         for (let pointOffset = 1; pointOffset < this.body.bodyPoints.points.length; pointOffset++) {

    //             const testPointIdx = (pointIdx + pointOffset) % this.body.bodyPoints.points.length
    //             const testPointNext = (testPointIdx + 1) % this.body.bodyPoints.points.length
    //             const testLine = new AyisenMath.Line.Line.PointLine(this.body.bodyPoints.points[testPointIdx], this.body.bodyPoints.points[testPointNext])

    //             // Is there a collision here?
    //             if (lineOfRelevance.doesIntersect(testLine)) {
    //                 swapsNeeded.push({
    //                     l1p1Idx: mainPointIdx,
    //                     l1p2Idx: mainPointNextIdx,
    //                     l2p1Idx: testPointIdx,
    //                     l2p2Idx: testPointNext
    //                 })
    //                 // instruct each point to swap
    //                 swapMap[mainPointIdx] = {firstPt: testPointIdx, lastPt: testPointNext}
    //                 swapMap[testPointNext] = {firstPt: mainPointIdx, lastPt: mainPointNextIdx}
    //                 swapMap[testPointIdx] = {firstPt: mainPointIdx, lastPt: mainPointNextIdx}
    //                 swapMap[mainPointNextIdx] = {firstPt: testPointIdx, lastPt: testPointNext}

    //                 // intersectionMapping
    //                 /**
    //                  * Here; I need to figure out WHICH ELEMENT IS ON THE SAME SIDE OF THE JUNCTURE AS THIS ONE AND WHICH IS NOT.
    //                  * That's critical. I just know that they intersect at the moment.
    //                  * How would i figure which is on the same side? Hmm....
    //                  * I think I may need to write some functionality that "walks the other way until we find a point.
    //                  * That will be tricky... but it might be the only way....
    //                  */
    //                 intersectionMapping[mainPointIdx] = {
    //                     sameSideOfJunctureSibling: 0, // todo
    //                     acrossJuncture: {
    //                         crossMaker: mainPointNextIdx,
    //                         target: 0 // todo
    //                     }
    //                 }

    //                 break // found colliding line! no need to test more lines
    //             }

    //         }

    //     }

    //     if (swapsNeeded.length === 0) {
    //         foundSwapsLastRun = false
    //         break
    //     }

    //     // Hack: break while loop right away, currently infinite run
    //     foundSwapsLastRun = false

    //     // POTENTIAL BUG: once we swap thre may be more overlaps created lolol
    //     // for (let swapNeeded of swapsNeeded) {
    //     //     // console.warn("Intersection detected")

    //     //     // Swap all the points!
    //     //     // Just swap the back points at their idxs
    //     //     const backPoint1Idx = swapNeeded.l1p2Idx
    //     //     const backPoint1 = this.body.bodyPoints.points[backPoint1Idx]
    //     //     const backPoint2Idx = swapNeeded.l2p2Idx
    //     //     const backPoint2 = this.body.bodyPoints.points[backPoint2Idx]

    //     //     this.body.bodyPoints.points[backPoint1Idx] = backPoint2
    //     //     this.body.bodyPoints.points[backPoint2Idx] = backPoint1

    //     // }
    //     const newPoints = []
    //     let currentPointIdx = 0
    //     let currentStep = 1
    //     const startPointIdx = currentPointIdx
    //     while (newPoints.length < this.body.bodyPoints.points.length) {

    //         // OLDER RENDITION
    //         // Add the point to new pts
    //         newPoints.push(this.body.bodyPoints.points[currentPointIdx])

    //         // Anywhere specific to go?
    //         if (swapMap[currentPointIdx] !== undefined) {

    //             // Move to the start if you're moving forward rn
    //             if (currentStep > 0) {
    //                 currentPointIdx = swapMap[currentPointIdx].firstPt
    //             }
    //             else {
    //                 currentPointIdx = swapMap[currentPointIdx].lastPt
    //             }

    //             // Move to back if you're moving backwards
    //             currentStep *= -1
    //         }
    //         else {
    //             currentPointIdx += currentStep
    //         }

    //         if (currentPointIdx === startPointIdx)
    //             break;

    //     }

    //     this.body.bodyPoints.points = newPoints

    // }

    // We need to identify WHAT needs to be merged, which is the tricky part.

    // TODO @Marcel: Bounce back if lines are about to be crossed.
    // AH... this won't catch intersection w/ far away lines.

    /**
     * What might happen is... point-trajectory crosses a line.
     *
     * TIME of that line is important. The line will move too.
     *
     * If point-trajectory crosses ANY line in its final position OR initial condition; we need to bounce.
     *
     * Bouncing CAN CAUSE OTHER PARITY VIOLATIONS. :/
     *
     * Is there a pre-emptive approach we could take?
     *
     * Check if the parity MIGHT be violated, then do something to protect?
     */

    /**
     * Another way one might handle this;
     * -> Recomputing the order of points once if overlap is detected.
     */

    /**
     * If you're about to move through a wall;
     *
     * Realistically , there's a tension graph running through the Wall that will tell us how much it'll give or push back...
     *
     * That's a little more involved than I'm hoping for LOL.
     *
     * I mean... at the most primitive; the particle is pushing with it's KE on the wall.....
     *
     */
  }
}

/**
 * The idea would be; moderately simple rules on the Amoeba allowing motion.
 * [] -> Tension between points (phys)
 * [] -> Ability to move certain points towards "rewards"
 *  -> Lift points
 *  -> Move them until "strength" is outweighed by tension.
 *  -> Put them down
 *  -> Allow tension to cause pulling to happen
 *
 * [] -> Absorption
 *  -> When points cross; they just merge
 *  -> Multiple Amoeba's can merge into 1
 */
export default class Amoeba extends Fidget {
  // Class members
  // bubbles: Bubble[] = [];
  dragTrigger: boolean = true

  creatures: AmoebaObj[] = []

  getCreatureInCreation = () => {
    if (
      this.creatures.length > 0 &&
      this.creatures[this.creatures.length - 1].isInCreation()
    ) {
      return this.creatures[this.creatures.length - 1]
    }
    return null
  }

  onMouseDown = (e: PointEvent) => {
    this.creatures.push(
      new AmoebaObj(
        {
          bodyPoints: new SmartGeo([]),
          color: Random.randomColor(),
          energy: [],
        },
        true,
      ),
    )
  }

  onDrag = (e: PointEvent) => {
    const creatureInCreation = this.getCreatureInCreation()
    if (creatureInCreation) {
      creatureInCreation.body.bodyPoints.points.push(R2.newVector(e.x, e.y))
    }
  }

  mouseUp = (e: PointEvent) => {
    const creatureInCreation = this.getCreatureInCreation()
    if (
      creatureInCreation &&
      creatureInCreation.body.bodyPoints.points.length <= 2
    ) {
      this.creatures.pop()
      return
    }
    if (creatureInCreation) {
      creatureInCreation.completeCreation()
    }
  }

  findHovered = (e: PointEvent) => {
    // Check if the point is within the x / y bounds of the geometry
    // ... we've gotta cache some stuff along with these LOL it's gonna get messy to do this
    // - Bounding Box
    // When it comes to hit test... We can draw a line through the point & see how many times it intersets the Geo.
    // But... have to check "n = num sides"  times.... That's pretty expensive.
  }

  onClick = (e: PointEvent) => {
    console.log("Num Creatures: ", this.creatures.length)
    if (this.creatures.length === 1) {
      // this.creatures[0].giveIndicesAgenda([0, 1, 2, 3, 4, 5, 6], R2.newVector(0, 0), 100)

      /**
       * This... needs to be a little more fleshed out...
       *
       * Striving for a location.... I need to know how much strength to put into it....
       *
       * Specifically... how much energy should be put into the ask -> how to distribute it over time...
       *
       * Probably a mix of a
       * -> Sine wave controlling amplitude of energy
       * -> "T since last reneder" controlling amp of energy (sine gives "amp / sec target" & this gives amp to apply)
       *
       */
      this.creatures[0].body.strive = {
        indices: [0, 1, 2, 3, 4, 5, 6],
        target: R2.newVector(0, 0),
        pullPeriodMS: 2000,
        reachingPeriodMS: 4000,
        pausingPeriodMS: 8000,
        peakKEPerSec: 6,
      }
    }
  }

  // Render Frame
  renderFrame = async () => {
    if (!this.ctx) return

    // Render / Update ripples
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    // Get meta breath multiplier
    // let metaT =  (Date.now() - this.simulator.initT) / 1000;
    // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
    // metaInterp = (metaInterp + 1) / 2;
    let metaInterp = 1

    const bubbles: Bubble[] = this.sharedState.read() ?? []

    this.creatures.forEach((creature) => {
      if (!this.ctx) return

      // Evolve points
      if (this.lastRenderT) {
        const dtSeconds = (Date.now() - this.lastRenderT) / 1000

        // Strive
        if (this.initT) {
          creature.strive(Date.now() - this.initT, dtSeconds)
        }

        // console.log('Dt: ', dtSeconds)
        if (!creature.isInCreation()) creature.updatePositions(dtSeconds)
      }

      // TODO: This motion is flawed :-(
      // - Doesn't update the actual location of the object
      // - If you tesselate during motion; the motion doesn't continue.
      const geoPoints: Geometry = creature.body.bodyPoints.points.map((p) => {
        // if (geo.motableDelta) {
        //     const offset = getCurMotionValueu(geo.motableDelta)
        //     return R2.add(offset.delta, p)
        // }
        return p
      })

      VisualEngine.canvas.drawSmoothGeoApprox(
        this.ctx,
        geoPoints,
        5,
        creature.body.color,
        !creature.isInCreation(),
        creature.isInCreation(),
      )
    })

    // bubbles.forEach((bubble, idx) => {

    //     // Set current radius
    //     const relT = (Date.now() - bubble.initial.t) / 1000;
    //     let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod));

    //     // Range : -1 to 1 -> 0 to 1
    //     interpVal = (interpVal + 1) / 2;
    //     interpVal *= metaInterp

    //     // Set Radus : range 0 to maxRad
    //     bubble.radius = bubble.maxRad * (interpVal)

    //     // Set opacity : range 0.2 to 0.8
    //     const opacity = (interpVal * 0.6) + 0.2

    //     if (!this.ctx)
    //         return;

    //     VisualEngine.canvas.drawBubble(
    //         this.ctx,
    //         bubble.current.location,
    //         bubble.radius,
    //         bubble.current.color,
    //         opacity
    //     );
    // });

    // Remove as needed.
    for (let idx of toRemove) {
      bubbles.splice(idx, 1)
      console.log("Removal")
    }
    if (toRemove.length > 0)
      this.sharedState.write(bubbles, SharedStateEntity.FIDGET)
  }
}
