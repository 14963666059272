import Fidget, {
  PointEvent,
  SharedStateEntity,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"
import { Geometry, tesselateGeometry } from "../../libs/Math/Geometry"
import Random from "../../libs/Math/Random"
import { getCurMotionValueu, MotableDelta } from "../../libs/Math/Motion"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

export interface Bubble {
  breathPeriod: number

  maxRad: number

  initial: {
    t: number
  }

  current: {
    location: R2.Vector
    radius: number
    t: number
    color: string
  }

  // Running Vars
  radius?: number
}

export interface FullGeo {
  geo: Geometry

  color: string

  motableDelta?: MotableDelta
}

export default class Fission extends Fidget {
  // Class members
  // bubbles: Bubble[] = [];
  dragTrigger: boolean = true

  geos: FullGeo[] = []

  // Bubble management
  addBubble(x: number, y: number, period: number, r: number, color: string) {
    // Create bubble and add to the list
    const bubble: Bubble = {
      // Breath period (s) (0 -> r -> 0)
      breathPeriod: period,

      maxRad: r,

      initial: {
        t: Date.now(),
      },

      current: {
        location: R2.newVector(x, y),
        radius: r,
        t: Date.now(),
        color: color,
      },
    }

    let bubbles: Bubble[] | null = this.sharedState.read()
    console.warn("Bubbles: ", bubbles)
    if (!bubbles) bubbles = []
    bubbles.push(bubble)
    console.warn("Pushing Bubbles: ", bubbles)
    this.sharedState.write(bubbles, SharedStateEntity.FIDGET)
    // this.bubbles.push(bubble);

    // TODO @Marcel: This is hacky. The state should all be in the sync state in the first place
    // this.sharedState.write([...this.bubbles], SharedStateEntity.FIDGET)
  }

  onDrag = (e: PointEvent) => {
    if (this.dragTrigger)
      this.addBubble(
        e.x,
        e.y,
        AyisenMath.Random.getRandomFloat(2, 10),
        AyisenMath.Random.getRandomInt(50, 100),
        AyisenMath.Random.randomColor(),
      )
  }

  findHovered = (e: PointEvent) => {
    // Check if the point is within the x / y bounds of the geometry
    // ... we've gotta cache some stuff along with these LOL it's gonna get messy to do this
    // - Bounding Box
    // When it comes to hit test... We can draw a line through the point & see how many times it intersets the Geo.
    // But... have to check "n = num sides"  times.... That's pretty expensive.
  }

  onClick = (e: PointEvent) => {
    // Add bubble
    // this.addBubble(e.x, e.y, AyisenMath.Random.getRandomFloat(2, 10), AyisenMath.Random.getRandomInt(50, 100), AyisenMath.Random.randomColor());

    if (this.geos.length === 0) {
      this.geos.push({
        geo: [
          { x: 0.25 * this.canvas.width, y: 0.25 * this.canvas.height },
          { x: 0.75 * this.canvas.width, y: 0.25 * this.canvas.height },
          { x: 0.75 * this.canvas.width, y: 0.75 * this.canvas.height },
          { x: 0.25 * this.canvas.width, y: 0.75 * this.canvas.height },
        ],
        color: Random.randomColor(),
      })
    } else {
      this.geos = this.geos.flatMap((geo) => {
        // TODO: Hax; we finishh applying the delta right to the geo before moving on
        let shape = geo.geo
        if (geo.motableDelta?.delta) {
          shape = shape.map((p) => {
            if (geo.motableDelta?.delta)
              return R2.add(p, geo.motableDelta.delta)
            return p
          })
        }

        const broken = tesselateGeometry(shape)
        if (broken) {
          const fullGeos: FullGeo[] = broken.map((e) => {
            return { geo: e, color: Random.randomColor() }
          })

          // Pick a drift offset :-)
          fullGeos.forEach((geo) => {
            const offsetX = Random.getRandomInt(-50, 50)
            const offsetY = Random.getRandomInt(-50, 50)

            // Assign a motable delta!
            geo.motableDelta = {
              delta: R2.newVector(offsetX, offsetY),
              startT: Date.now(),
              duration: 1000,
            }
          })

          return fullGeos
        }
        return [geo]
      })
    }
  }

  // Render Frame
  renderFrame = async () => {
    if (!this.ctx) return

    // Render / Update ripples
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    // Get meta breath multiplier
    // let metaT =  (Date.now() - this.simulator.initT) / 1000;
    // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
    // metaInterp = (metaInterp + 1) / 2;
    let metaInterp = 1

    const bubbles: Bubble[] = this.sharedState.read() ?? []

    this.geos.forEach((geo) => {
      if (!this.ctx) return

      // TODO: This motion is flawed :-(
      // - Doesn't update the actual location of the object
      // - If you tesselate during motion; the motion doesn't continue.
      const geoPoints: Geometry = geo.geo.map((p) => {
        if (geo.motableDelta) {
          const offset = getCurMotionValueu(geo.motableDelta)
          return R2.add(offset.delta, p)
        }
        return p
      })

      VisualEngine.canvas.drawGeometry2(this.ctx, geoPoints, 5, geo.color, true)
    })

    // bubbles.forEach((bubble, idx) => {

    //     // Set current radius
    //     const relT = (Date.now() - bubble.initial.t) / 1000;
    //     let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod));

    //     // Range : -1 to 1 -> 0 to 1
    //     interpVal = (interpVal + 1) / 2;
    //     interpVal *= metaInterp

    //     // Set Radus : range 0 to maxRad
    //     bubble.radius = bubble.maxRad * (interpVal)

    //     // Set opacity : range 0.2 to 0.8
    //     const opacity = (interpVal * 0.6) + 0.2

    //     if (!this.ctx)
    //         return;

    //     VisualEngine.canvas.drawBubble(
    //         this.ctx,
    //         bubble.current.location,
    //         bubble.radius,
    //         bubble.current.color,
    //         opacity
    //     );
    // });

    // Remove as needed.
    for (let idx of toRemove) {
      bubbles.splice(idx, 1)
      console.log("Removal")
    }
    if (toRemove.length > 0)
      this.sharedState.write(bubbles, SharedStateEntity.FIDGET)
  }
}

// export default class Bubbles {

//     constructor(canvas) {

//         // Save canvas & context
//         this.canvas = canvas;
//         this.ctx = canvas.getContext('2d');

//         this.dragTrigger = true;

//         // Attach canvas handlers

//         // Ripple management
//         this.currentBubbles = [];

//         // Bind functions
//         this.renderFrame = this.renderFrame.bind(this);
//         this.handleClick = this.handleClick.bind(this);
//         this.handleDrag = this.handleDrag.bind(this);

//         // Simulation handlers
//         const simHandlers = {
//             onClick: e => this.handleClick(e),
//             onDrag: e => this.handleDrag(e),
//         }

//         // Attach simulator
//         this.simulator = new Simulator(canvas, this.renderFrame, ()=> {}, simHandlers);
//         this.simulator.simulate();

//     }

//     }

//     // Animate frame
//     renderFrame () {

//         // Render / Update ripples

//         this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

//         const toRemove = [];

//         // Get meta breath multiplier
//         // let metaT =  (Date.now() - this.simulator.initT) / 1000;
//         // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
//         // metaInterp = (metaInterp + 1) / 2;
//         let metaInterp = 1;

//         this.currentBubbles.forEach((bubble, idx) => {

//             // Set current radius
//             const relT = (Date.now() - bubble.initial.t) / 1000;
//             let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod));

//             // Range : -1 to 1 -> 0 to 1
//             interpVal = (interpVal + 1) / 2;
//             interpVal *= metaInterp

//             // Set Radus : range 0 to maxRad
//             bubble.radius = bubble.maxRad * (interpVal)

//             // Set opacity : range 0.2 to 0.8
//             const opacity = (interpVal * 0.6) + 0.2

//             canvasUtils.drawBubble(
//                 this.ctx,
//                 {x: bubble.current.x, y: bubble.current.y},
//                 bubble.radius,
//                 bubble.current.color,
//                 opacity
//             );
//         });

//         // Remove as needed.
//         for (let idx of toRemove)
//             this.currentBubbles.splice(idx, 1);

//     }

// }
