import React, { useState } from "react"
import { FidgetType, getLinkInformation } from "../constants/fidgetConfig"
import { useNavigate } from "react-router-dom"

import "../styles/Header.css"
import { useAtom, useAtomValue } from "jotai"
import { midiSettingAtom } from "../state/midi_keyboard_state"
import { LoadingState, loadingAtom } from "../state/loading"
import { isSettingsPanelOpenAtom } from "../state/settings_sidebar"
import { SettingsSidebar } from "./SettingsSidebars/SettingsSidebar"

interface HeaderProps {
  setLoadModalOpen: any
  loadingModalOpen: boolean
}

const Header = (props: HeaderProps) => {
  const [navOpen, setNavOpen] = useState(false)
  const navigate = useNavigate()

  const toHome = () => {
    setNavOpen(false)
    navigate("/")
  }

  const toLink = (link: string) => {
    setNavOpen(false)
    navigate(link)
  }

  const toggleNav = () => {
    setNavOpen(!navOpen)
  }

  const toggleLoadingModal = () => {
    props.setLoadModalOpen(!props.loadingModalOpen)
  }

  const [midiState, setMidiState] = useAtom(midiSettingAtom)
  const toggleMidiControls = () => {
    setMidiState({ ...midiState, shown: !midiState.shown })
  }

  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useAtom(
    isSettingsPanelOpenAtom,
  )
  const toggleSettingsPanel = () => {
    setIsSettingsPanelOpen(!isSettingsPanelOpen)
  }

  const loadingState = useAtomValue(loadingAtom)

  const loadingStateClass =
    loadingState === LoadingState.FAILED_TO_LOAD
      ? "loadingCircle_failed"
      : loadingState === LoadingState.LOADING
        ? "loadingCircle_loadingg"
        : loadingState === LoadingState.LOADED
          ? "loadingCircle_loaded"
          : "na"

  return (
    <div>
      <div className="Header">
        {/* Logo */}
        <img
          className="LogoText"
          id="ayisen-logo"
          src="/resources/AyisenLogoText.png"
          alt="AYISEN"
        ></img>

        {/* Nav Opener */}
        <img
          className="headerOpener"
          src="/logo.png"
          alt="Nav"
          onClick={toggleNav}
        />
        <img
          className="saveStateButton"
          src="/resources/icons/SaveState.png"
          alt="Nav"
          onClick={toggleLoadingModal}
        />
        <img
          className="headerOpener"
          src="/resources/icons/piano.png"
          alt="Midi"
          onClick={toggleMidiControls}
        />
        <img
          className="headerOpener"
          style={{ filter: "invert(1)" }}
          src="/resources/icons/settings.png"
          alt="Midi"
          onClick={toggleSettingsPanel}
        />

        <div className={"headerOpener loadingCircle " + loadingStateClass} />

        {/* Side Nav */}
        <div className={"navBar" + (navOpen ? " navBarOpen" : "")}>
          <NavBarSection
            title={"Toys"}
            fidgetType={FidgetType.TOY}
            goToLink={toLink}
          />
          <NavBarSection
            title={"Instruments"}
            fidgetType={FidgetType.INSTRUMENT}
            goToLink={toLink}
          />
        </div>

        {navOpen && (
          <div className="navBarExiter" onClick={() => setNavOpen(false)} />
        )}
      </div>
    </div>
  )
}

/**
 * Collapsable section within the navbar
 */
function NavBarSection(
  props: React.PropsWithChildren<{
    title: string
    fidgetType: FidgetType
    goToLink: (e: string) => void
  }>,
) {
  const pathRoot = "/" + window.location.pathname.split("/")[1]

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="navBarSection">
      <h2 onClick={() => setIsOpen(!isOpen)}>{props.title}</h2>
      {isOpen && (
        <div className="navBarSectionInner">
          {getLinkInformation()
            .filter((e) => e.type === props.fidgetType)
            .map((linkInfo, idx) => {
              return (
                <div
                  className={
                    "navOpt" +
                    (pathRoot === linkInfo.link ? " navOptActive" : "")
                  }
                  onClick={() => props.goToLink(linkInfo.link)}
                >
                  {linkInfo.display}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default Header
