import FidgetUtils from "../../utilities"
import Fidget, {
  PointEvent,
  SharedStateEntity,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"
import { Geometry, tesselateGeometry } from "../../libs/Math/Geometry"
import Random from "../../libs/Math/Random"
import { getCurMotionValueu, MotableDelta } from "../../libs/Math/Motion"
import { AyisenELTester } from "../../Engines/AudioEngine/ela/ela"
import { Polygon, PolygonRenderStyle } from "../../libs/Physics/Polygon"
import { CollisionResolver } from "../../libs/Physics/Collision"
import { Scene } from "../../libs/Physics/Scene"
import { Circle } from "../../libs/Physics/Circle"

// JS Library for Ripple Animations

export default class Physics extends Fidget {
  // Class members
  dragTrigger: boolean = true

  scene: Scene = {}

  hoveredId: string | null = null

  onDrag = (e: PointEvent) => {
    // Hovered over something?
    if (this.hoveredId) {
      // take it along for the ride!
      // OPTIMIZATION @mlegros: we could have some sort of "tmp transform" applied and only update points after
      this.scene[this.hoveredId].move(e.movement)

      // Reconcile any collisions
      CollisionResolver.checkForAndReflowCollisions(
        this.scene[this.hoveredId],
        this.scene,
      )
    }
  }

  onMouseDown = (e: PointEvent) => {
    this.updateHoveredItem(e)
  }

  onClick = (e: PointEvent) => {
    this.updateHoveredItem(e)

    // Only do soemthing if we're not hovering
    if (!this.hoveredId) {
      const sides = Random.getRandomInt(2, 8)
      if (sides === 2) {
        const obj = new Circle(e, 35)
        this.scene[obj.id] = obj
      } else {
        // Create a square at some random point & add it
        const square = Polygon.generateNGon(sides, e, 50)
        this.scene[square.id] = square
      }
    }
  }

  updateHoveredItem = (e: PointEvent) => {
    // Simple case :: persisting hover
    if (this.hoveredId) {
      if (this.scene[this.hoveredId].isPointWithin(e)) {
        return
      }
    }

    // Check for new hover
    for (let id of Object.keys(this.scene)) {
      if (this.scene[id].isPointWithin(e)) {
        this.hoveredId = id
        return
      }
    }

    // Reset hover
    this.hoveredId = null
  }

  onMove = (e: PointEvent) => {
    this.updateHoveredItem(e)
  }

  // Render Frame
  renderFrame = async () => {
    if (!this.ctx) return

    // Render / Update ripples
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    for (let obj of Object.values(this.scene)) {
      obj.draw(this.ctx, { style: PolygonRenderStyle.FILL })
    }
  }
}
