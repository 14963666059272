import ela from "../../Engines/AudioEngine/ela/ela"
import { AyisenSynth } from "../../Engines/AudioEngine/AyisenSynth/AyisenSynth"
import { getAtomValue } from "../../state/atomStore"
import { samplePathAtom } from "../../state/sampleState"
import { PlayableNote, semitonesBetweenNotes } from "../../types/audio/note"
import { el, NodeRepr_t } from "@elemaudio/core"
import { BouncingBallModel } from "../../Models/bouncingBall"
import { ElasticBounceBetweenPoints1d } from "../../Models/ElasticBounceBtPoints1d"
import { getCurSimElapsedTimeRn, simWidthMeters } from "../../state/sim"
import {
  ElasticBallBetween2Walls,
  ballRadiusMeters,
  bounceAtom,
  horizonatalVelocityAtom,
  startHeightAtom,
} from "./state"
import { SampleInfo } from "../../Engines/AudioEngine/ela/sample/types"

// This is the note that we assume the base sample plays when you hit. We shift around this
const BASE_NOTE_SHIFTED_FROM: PlayableNote = "C4"

const activeBallToAudioNode = (
  activeBall: ElasticBallBetween2Walls,
  sample: SampleInfo,
  semiToneShift?: number,
) => {
  // The time elapsed when we're releasing these audio computers....
  // This will offset the time computation done in the audio thread.
  // Should provide soooommee semblance of syncing. Not enough, but an okay start hopefully.
  // This value needs to be in sync with what the visual thread THINKS is the current elapsed time when we release the audio node.
  const elapsedTimeToStartAudioSimsWith = getCurSimElapsedTimeRn()

  // TODO (p1): Make these values adapt when the window size changes
  const widthOfMotion = getAtomValue(simWidthMeters) - activeBall.ballRadius
  let path = ElasticBounceBetweenPoints1d.getModel(
    activeBall.ballRadius,
    widthOfMotion,
    activeBall.horizontalVelocity,
  ).newAudioComputer(elapsedTimeToStartAudioSimsWith)

  // Normalize to between 0<->1
  // NOTE: Won't work if baseline is not 0
  path = el.div(path, widthOfMotion)

  let voicePlaybackNode = ela.arbitraryPathAlongSample(
    sample,
    path,
    semiToneShift,
  )

  // Augment via height
  // const height = activeBall.dropHeight
  // let bouncingMixIn = el.div(
  //   BouncingBallModel.getModel(height).newAudioComputer(
  //     elapsedTimeToStartAudioSimsWith,
  //   ),
  //   height, // normalize
  // )
  const bouncingMixIn = el.const({ value: 1 })

  voicePlaybackNode = el.mul(voicePlaybackNode, bouncingMixIn)

  return voicePlaybackNode
}

enum PlaybackMode {
  "all_balls_each_note",
  "one_ball_per_note",
}
const playbackMode: PlaybackMode = PlaybackMode.one_ball_per_note

export class ShatterSynth extends AyisenSynth {
  rerenderAudioFromState() {
    // Sample atom
    const sample = this.getActiveSample()
    if (!sample) return null

    // TODO: THERE'S A BUG WHERE IF THE LIST IS EMPTY, WE DON'T CORRECTLY RENDER TO NOTHING. this line hackily fixes that.
    if (this.voices.length === 0) {
      this.renderAudioNodes(el.const({ value: 0 }))
    }

    const activeBalls = getAtomValue(bounceAtom)
    if (activeBalls.length === 0) return

    // We need to create a path for each voice!
    const voiceNodes = this.voices.map((voice, idx) => {
      let voicePlaybackNode: NodeRepr_t | number = el.const({ value: 0 })

      switch (playbackMode) {
        case PlaybackMode.all_balls_each_note: {
          for (let activeBall of activeBalls) {
            // Figure out how many semi tones off we should shift....
            // TODO: better handling for the bad case :: defaulting to undefined here menas that either 0 semitones, or not-found -> render the C4 note
            let semiTones =
              semitonesBetweenNotes(BASE_NOTE_SHIFTED_FROM, voice.note) ??
              undefined

            voicePlaybackNode = el.add(
              voicePlaybackNode,
              activeBallToAudioNode(activeBall, sample, semiTones),
            )
          }
          break
        }
        case PlaybackMode.one_ball_per_note: {
          // Get the ball that corresponds with this voice.
          // (assumes voices are in order)
          const activeBall = activeBalls[idx % activeBalls.length]

          // Figure out how many semi tones off we should shift....
          // TODO: better handling for the bad case :: defaulting to undefined here menas that either 0 semitones, or not-found -> render the C4 note
          let semiTones =
            semitonesBetweenNotes(BASE_NOTE_SHIFTED_FROM, voice.note) ??
            undefined

          voicePlaybackNode = activeBallToAudioNode(
            activeBall,
            sample,
            semiTones,
          )
          break
        }
      }

      return voicePlaybackNode
    })

    // Render all!
    let renderable = el.add(...voiceNodes)
    this.renderAudioNodes(renderable)
    return renderable
  }
}
