import { atom } from "jotai"
import Random from "../../libs/Math/Random"
import { ModelComputer } from "../../Models/Model"
import { BouncingBallModel } from "../../Models/bouncingBall"
import { ElasticBounceBetweenPoints1d } from "../../Models/ElasticBounceBtPoints1d"
import { simWidthMeters } from "../../state/sim"

export const startHeightAtom = atom<number>(5)
export const horizonatalVelocityAtom = atom<number>(8)
export const ballRadiusMeters = atom<number>(0.2)

export interface ElasticBallBetween2Walls {
  dropHeight: number
  horizontalVelocity: number
  ballRadius: number
  color: string
  // (the size of the sim is used in some of these computations.... so it's a composite atom...)
  // vertical: ModelComputer<number>
  // horizonatal: ModelComputer<number>
}

const originalState: ElasticBallBetween2Walls[] = [
  {
    dropHeight: 5,
    horizontalVelocity: 8,
    ballRadius: 0.2,
    color: "blue", //Random.randomColor(),
    // vertical: BouncingBallModel.getModel(5),
    // horizonatal: ElasticBounceBetweenPoints1d.getModel()
  },
  {
    dropHeight: 8,
    horizontalVelocity: 7,
    ballRadius: 0.2,
    color: "orange", //Random.randomColor(),
  },
]

export const bounceAtom = atom<ElasticBallBetween2Walls[]>(originalState)

/**
 * *** The problem here is that stakeholders in these models need to know things like
 * - What's the width of motion for this ball (padded by the radius) so we can normalize sample reading?
 * Just having a cached compute module obfiscates some of that info.
 */
// export const bounceModelAtoms = atom((get) => {
//   // Grab the bounce atom
//   const allBouncingBalls = get(bounceAtom)

//   return allBouncingBalls.map((ball) => {
//     const verticalMotionModel = BouncingBallModel.getModel(ball.dropHeight)

//     const widthOfMotion = get(simWidthMeters) - ball.ballRadius
//     const horizonatalMotionModel = ElasticBounceBetweenPoints1d.getModel(
//       ball.ballRadius,
//       widthOfMotion,
//       ball.horizontalVelocity,
//     )

//     return {
//       verticalMotionModel,
//       horizonatalMotionModel,
//     }
//   })
// })
