import { useState } from "react"
import { ShatterSynth } from "./synth"
import { useAtom } from "jotai"

import { FidgetId } from "../../constants/fidgetConsts"
import { SimInstrument } from "../../components/Sim/SimInstrument"
import BounceSim from "./sim"
import { SettingsSidebar } from "../../components/SettingsSidebars/SettingsSidebar"
import { SampleSelector } from "../../components/SampleSelector"
import { ElasticBallBetween2Walls, bounceAtom } from "./state"
import "./index.css"
import { NiceNumberEditor } from "../../components/ValueEditors/NiceNumberEditor"
export function Bounce() {
  // Shatter synth, new instance on mount
  const [synth, setSynth] = useState<ShatterSynth>(new ShatterSynth())

  return (
    <>
      <SimInstrument
        createNewSimulator={(canvas: HTMLCanvasElement) => {
          return new BounceSim(canvas, FidgetId.BOUNCE)
        }}
        synth={synth}
      >
        <BounceSidebar />
      </SimInstrument>
    </>
  )
}

function BounceSidebar() {
  return (
    <SettingsSidebar>
      <h3>Sample</h3>
      <SampleSelector />
      <BallConfigEditor />
    </SettingsSidebar>
  )
}

function BallConfigEditor() {
  const [balls, setBalls] = useAtom(bounceAtom)

  return (
    <div className="configEditor">
      {balls.map((ball, idx) => {
        return <BallEditor ball={ball} ballIdx={idx} />
      })}
    </div>
  )
}

function BallEditor({
  ball,
  ballIdx,
}: {
  ball: ElasticBallBetween2Walls
  ballIdx: number
}) {
  const [balls, setBalls] = useAtom(bounceAtom)

  return (
    <div>
      <h3>Ball {ballIdx}</h3>

      <NiceNumberEditor
        label={"Drop height"}
        value={ball.dropHeight}
        onChange={(value: number) => {
          const newBalls = [...balls]
          newBalls[ballIdx].dropHeight = value
          setBalls(newBalls)
        }}
      />
      <NiceNumberEditor
        label={"Ball radius"}
        value={ball.ballRadius}
        onChange={(value: number) => {
          const newBalls = [...balls]
          newBalls[ballIdx].ballRadius = value
          setBalls(newBalls)
        }}
      />
      <NiceNumberEditor
        label={"Horizontal velocity"}
        value={ball.horizontalVelocity}
        onChange={(value: number) => {
          const newBalls = [...balls]
          newBalls[ballIdx].horizontalVelocity = value
          setBalls(newBalls)
        }}
      />
    </div>
  )
}
