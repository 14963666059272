import { GRAV_A_EARTH } from "../libs/Physics/constants"
import { el, NodeRepr_t } from "@elemaudio/core"
import { getAtomValue } from "../state/atomStore"
import { audioCoreAtom } from "../Engines/AudioEngine/core/AudioCore"
import WebAudioRenderer from "@elemaudio/web-renderer"
import { ModelComputer } from "./Model"
import { generateUUID } from "three/src/math/MathUtils"

/**
 * Visual & Audio model for the following:
 * -> A ball that falls with earth's gravity, and experiences an elastic collision with the ground.
 */
export class BouncingBallModel {
  static getModel(dropHeight: number): ModelComputer<number> {
    // ------- Shared constants -------
    // const behaviorPeriod = 4 * dropHeight
    const behaviorPeriod =
      (2 * Math.sqrt(2 * dropHeight)) / Math.sqrt(GRAV_A_EARTH)

    // ------- Visual vals -------
    const computeHeightAtT = (tElapsedS: number) => {
      // Period behavior clamping
      const relativeT = tElapsedS % behaviorPeriod

      // Motion equation
      const term1 = Math.sqrt(2 * GRAV_A_EARTH * dropHeight)
      const term2 = -0.5 * GRAV_A_EARTH * relativeT
      const displacement = relativeT * (term1 + term2)

      return displacement
    }

    // TODO: Better Key-Usage In these models!
    const constructAudioComputer = (tElapsedOnStart: number) => {
      const el_behaviroPeriod = el.const({ value: behaviorPeriod })

      // todo: anchor on the same time point.... not sure how tho
      // NOTE: Keying here if very important. It forces the counter to not be re-used inappropriately (which can lead to the ElapsedOnStart seeming inaccurate)
      const t_elapsed_seconds = el.add(
        { key: generateUUID() },
        el.const({ value: tElapsedOnStart }),
        el.div(el.counter({ key: generateUUID() }, 1), el.sr()),
      )
      const el_relative_t = el.mod(t_elapsed_seconds, el_behaviroPeriod)

      const term1 = el.const({
        value: Math.sqrt(2 * GRAV_A_EARTH * dropHeight),
      })
      const term2 = el.mul(
        el.const({ value: -0.5 * GRAV_A_EARTH }),
        el_relative_t,
      )
      const el_displacement = el.mul(el_relative_t, el.add(term1, term2))

      return el_displacement
    }

    return {
      valueAtT: computeHeightAtT,
      newAudioComputer: constructAudioComputer,
    }
  }
}
