import { el } from "@elemaudio/core"

import Fidget, { PointEvent } from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"

import { v4 as uuidv4 } from "uuid"
import { sampleInfoToSignal } from "../../Engines/AudioEngine/sampling/SampleHandler"
import { countReset } from "console"
import ela, { AyisenELTester } from "../../Engines/AudioEngine/ela/ela"
import { FidgetId } from "../../constants/fidgetConsts"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

export interface Ripple {
  // Growth rate = change in Radius / s
  growthRate: number
  duration: number

  initial: {
    location: R2.Vector
    radius: number
    width: number
    t: number
    color: string
  }
  current: {
    location: R2.Vector
    radius: number
    width: number
    t: number
    color: string
  }
}

export default class Sonic extends Fidget {
  ripples: Ripple[] = []
  dragTrigger = true

  constructor(canvas: HTMLCanvasElement) {
    super(canvas, FidgetId.SONIC)
  }

  onClick = (e: PointEvent) => {
    // Add ripple
    // this.addRipple(e.x, e.y, 5, 100, AyisenMath.Random.randomColor());
    // const tester = new AyisenELTester(this.audioCore);
    // tester.pitchedDelay();
  }

  onDrag = (e: PointEvent) => {
    if (this.dragTrigger)
      this.addRipple(e.x, e.y, 5, 100, AyisenMath.Random.randomColor())
  }

  // Ripple management
  addRipple(x: number, y: number, w: number, r: number, color: string) {
    // Create ripple and add to the list
    const ripple: Ripple = {
      // Growth rate = change in Radius / s
      growthRate: 40,
      duration: 2,

      initial: {
        location: R2.newVector(x, y),
        radius: r,
        width: w,
        t: Date.now(),
        color: color,
      },
      current: {
        location: R2.newVector(x, y),
        radius: r,
        width: w,
        t: Date.now(),
        color: color,
      },
    }

    this.ripples.push(ripple)
  }

  // Animate frame
  renderFrame = async () => {
    if (!this.ctx) return

    // Render / Update ripples

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    this.ripples.forEach((ripple, idx) => {
      // Update radius
      const relT = (Date.now() - ripple.initial.t) / 1000
      ripple.current.radius = ripple.initial.radius + relT * ripple.growthRate
      const interp = relT / ripple.duration
      const fadeOut = 1 - interp

      if (interp >= 1) {
        toRemove.push(idx)
        return
      }

      if (!this.ctx) return

      VisualEngine.canvas.drawRipple(
        this.ctx,
        ripple.current.location.x,
        ripple.current.location.y,
        ripple.current.radius,
        ripple.current.width,
        ripple.current.color,
        fadeOut,
      )
    })

    // Remove as needed.
    for (let idx of toRemove) this.ripples.splice(idx, 1)
  }
}
