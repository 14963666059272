import { R1 } from "./R1"
import { R2 } from "./R2"
export interface Body {
  location: R2.Vector
  velocity: R2.Vector

  width: number
}

export const keToV = (ke: number, mass: number = 1) => {
  // KE COULD be negative. we'll bake that out then back in
  let effectiveKE = ke
  let negative = false
  if (ke < 0) {
    negative = true
    effectiveKE /= -1
  }

  let output = Math.sqrt((effectiveKE * 2) / mass)

  if (negative) output *= -1

  return output
}
export const getKe = (body: Body, mass: number = 1) => {
  const ke = R2.newVector(
    0.5 * mass * Math.pow(body.velocity.x, 2),
    0.5 * mass * Math.pow(body.velocity.y, 2),
  )
  return ke
}
export const addKe = (body: Body, energy: R2.Vector, mass: number = 1) => {
  // Get body KE
  // NOTE: This turns the body's ke positive no matter what
  let ke = getKe(body)

  ke.x = ke.x + energy.x
  ke.y = ke.y + energy.y

  // TODO: this is a little jank: energy can't be negative, b/c we're takign the sqrt!
  // NOTE: This just assumes that all the final ke is getting added in the direction of the velocity!
  // So, inputing negative KE really means KE that's fighting the vector.
  // Positive means in the direction of the vector.
  // ... this is actually good, it's whether we're putting energy into the system or taking it out.
  body.velocity.x = R1.getNumberSign(body.velocity.x) * keToV(Math.abs(ke.x))
  body.velocity.y = R1.getNumberSign(body.velocity.y) * keToV(Math.abs(ke.y))
  console.log("Converted: ", body.velocity)
}

export const updateBodyLoc = (
  body: Body,
  lastRenderT: number,
  frictionForce: number | undefined = undefined,
  wallBounceEnergy: number | undefined,
) => {
  // Update based on velocity, and time elapsed.
  /*

    Can probably use some sort of like stocastic update... that's prob find for this sim. 

    Don't want to base it off of the global time... b/c forces can act on it in between. 

    */

  const dummyMass = 1

  if (!lastRenderT) return body

  // Get increment in seconds
  const timeStepS = (Date.now() - lastRenderT) / 1000

  // Update the location (pixels per second)
  body.location.x = body.location.x + body.velocity.x * timeStepS
  body.location.y = body.location.y + body.velocity.y * timeStepS

  const objWidth = body.width ?? 0

  // L-Wall collision?
  if (body.location.x - objWidth / 2 < 0 && body.velocity.x < 0) {
    body.velocity.x *= -1
    if (wallBounceEnergy) addKe(body, R2.newVector(wallBounceEnergy, 0))
  }

  // R-Wall collision?
  else if (body.location.x + objWidth / 2 > 1 && body.velocity.x > 0) {
    body.velocity.x *= -1
    if (wallBounceEnergy) addKe(body, R2.newVector(wallBounceEnergy, 0))
  }

  // T-Wall collision?
  else if (body.location.y - objWidth / 2 < 0 && body.velocity.y < 0) {
    body.velocity.y *= -1
    if (wallBounceEnergy) addKe(body, R2.newVector(wallBounceEnergy, 0))
  }

  // B-Wall collision?
  else if (body.location.y + objWidth / 2 > 1 && body.velocity.y > 0) {
    body.velocity.y *= -1
    if (wallBounceEnergy) addKe(body, R2.newVector(wallBounceEnergy, 0))
  }

  // TODO @Marcel: Friction
  if (frictionForce) {
    // TODO @Marcel: Clean up documentation / more rigorous def of energy, etc!
    // (units, etc)

    const { norm, mag } = R2.norm(body.velocity)

    // Work done
    const work = frictionForce * timeStepS

    // Remove energy
    let ke = 0.5 * Math.pow(mag, 2)

    let minKe = 0.001

    if (ke > minKe) {
      ke -= work
      if (ke < minKe) ke = minKe

      let newMag = Math.sqrt(2 * ke)

      // Set new velocity
      body.velocity = R2.newVector(norm.x * newMag, norm.y * newMag)

      if (ke) {
        // TODO: Remove
      }
      // Remove the corresponding energy
    }
  }

  return body
}

export default {
  updateBodyLoc,
}
