import { Line } from "./Line"
import { R2 } from "./R2"
import Random from "./Random"

/**
 * A geometry is composed of a series of points....
 *
 * Those points are assumed to be connected IN ORDER, with the starting point touching the closing point.
 *
 */
export type Geometry = Array<R2.Vector>

/**
 * Array of angles
 */
export type PolarGeometry = Array<number>

/** Find the minimum distance b/t 2 indices in a wrapping array */
const distBetweenIndices = (idx1: number, idx2: number, arrayLen: number) => {
  /**
   * [0, 1, (2), 3, 4, 5, (6), 7, 8, 9]
   * (len = 10)
   *
   * No wrap dist = 4
   * Wrap dist = 6
   */

  // Find the distance in each wrapping dir and choose the smallest
  const distNoWrap = Math.abs(idx2 - idx1)

  // How far over would 2 need to step to get to 1? 1 to get to 2?
  // We want to know how many items are under the min & over the max
  const min = Math.min(idx1, idx2)
  const max = Math.max(idx1, idx2)

  const distWrap = min + (arrayLen - max)

  return Math.min(distNoWrap, distWrap)
}

export type GeoemetryEdgeNodes = Array<{
  // Point info (idx or vector, hmmm)
  // Some will be phantoms, so best to be the actual point.
  point: R2.Vector

  isReal: boolean
}>

/**
 * Convert geos to a format thats easier for edge fission
 */
export const geoToEdgeNodes: (geo: Geometry) => GeoemetryEdgeNodes = (
  geo: Geometry,
) => {
  return geo.map((p) => {
    return {
      point: p,
      isReal: true,
    }
  })
}

export const generateNGon = (n: number, center: R2.Vector, r: number) => {
  let points: R2.Vector[] = []

  // Align so that an EDGE is along the top, not a point.
  // So, the first point should go to the right of 90deg by half of the length to the next point
  const angleBetweenPts = (2 * Math.PI) / n
  const angleOffset = Math.PI / 2 - angleBetweenPts / 2

  // For each point...
  for (let i = 0; i < n; i++) {
    const angle = (angleOffset + (2 * Math.PI * i) / n) % (2 * Math.PI)

    points.push({
      x: center.x + r * Math.cos(angle),
      y: center.y + r * Math.sin(angle),
    })
  }

  return points
}

interface FisableEdgePoint {
  point: R2.Vector
  isReal: boolean
}

interface LineIntersection {
  line1: { p1Idx: number; p2Idx: number }
  line2: { p1Idx: number; p2Idx: number }
}
interface LineIntersectionMapping {
  [key: number]: LineIntersection
}

function efficientLineIntersectionMap(lineIntersections: LineIntersection[]) {
  const mapping: LineIntersectionMapping = {}
  for (let intersection of lineIntersections) {
    mapping[intersection.line1.p1Idx] = { ...intersection }
    mapping[intersection.line1.p2Idx] = { ...intersection }
    mapping[intersection.line2.p1Idx] = {
      line1: intersection.line2,
      line2: intersection.line1,
    }
    mapping[intersection.line2.p2Idx] = {
      line1: intersection.line2,
      line2: intersection.line1,
    }
  }
  return mapping
}

export class SmartGeo {
  points: Geometry

  constructor(points: Geometry) {
    this.points = points
  }

  safeNextIdx(idx: number, step: number = 1) {
    return (idx + step) % this.points.length
  }

  checkForIntersectingLines(
    line: Line.PointLine,
    config: { ignoreStartPoints: number[]; stopAfterFirst: boolean } = {
      ignoreStartPoints: [],
      stopAfterFirst: true,
    },
  ) {
    const intersectingPointLines: { p1Idx: number; p2Idx: number }[] = []

    for (let pointIdx = 0; pointIdx < this.points.length; pointIdx++) {
      if (config.ignoreStartPoints.includes(pointIdx)) continue

      const p1Idx = pointIdx
      const p2Idx = this.safeNextIdx(pointIdx, 1)

      const testLine = new Line.PointLine(
        this.points[p1Idx],
        this.points[p2Idx],
      )

      // Is there a collision here?
      if (line.doesIntersect(testLine)) {
        intersectingPointLines.push({
          p1Idx,
          p2Idx,
        })
        if (config.stopAfterFirst) return intersectingPointLines
      }
    }

    return intersectingPointLines
  }

  /**
   * Identifies where a geometry intesects with itself, and returns the data in a helpful format
   *
   */
  findSelfIntersections() {
    const intersections: LineIntersection[] = []

    // For every point in the body...
    for (let pointIdx = 0; pointIdx < this.points.length; pointIdx++) {
      const mainPointIdx = pointIdx
      const mainPointTarget = this.safeNextIdx(mainPointIdx)
      const mainLine = new Line.PointLine(
        this.points[mainPointIdx],
        this.points[mainPointIdx],
      )

      // Any intersections
      const lineIntersections = this.checkForIntersectingLines(mainLine, {
        ignoreStartPoints: [mainPointIdx, mainPointTarget],
        stopAfterFirst: true,
      })

      if (lineIntersections.length > 0) {
        // catalog this intersection
        intersections.push({
          line1: { p1Idx: mainPointIdx, p2Idx: mainPointTarget },
          line2: lineIntersections[0],
        })
      }
    }

    return intersections
  }

  /**
   * Find loops within this geometry, and return them as point-index lists
   */
  findLoops() {
    // Start by locating any intersections.
    const intersections = efficientLineIntersectionMap(
      this.findSelfIntersections(),
    )

    // Now; we have information enough to start forming loops.
    if (Object.keys(intersections).length === 0) {
      return []
    }

    // each entry is an array of bodyPointIdxs, representing the loop
    const loops: number[][] = []

    const remainingPointIdxs: number[] = []
    for (let i = 0; i < this.points.length; i++) remainingPointIdxs.push(i)

    // todo: re-package the intersections so that they're maps for querying.

    while (loops.flatMap((e) => e).length < this.points.length) {
      const startingPointIdx = remainingPointIdxs[0]
      let curPointIdx = startingPointIdx
      const collectedPoints: number[] = []

      // Walk one way to an intersection
      let intersection1: LineIntersection | null = null
      while (!intersection1) {
        // check if the point has an intersection
        const curIntersection = intersections[curPointIdx]
        if (curIntersection) {
          intersection1 = curIntersection
        } else {
          curPointIdx = this.safeNextIdx(curPointIdx, 1)
        }
        collectedPoints.push(curPointIdx)
      }

      // Walk the other way to an intersection
      let intersection2: LineIntersection | null = null
      while (!intersection2) {
        // check if the point has an intersection
        const curIntersection = intersections[curPointIdx]
        if (curIntersection) {
          intersection2 = curIntersection
        } else {
          curPointIdx = this.safeNextIdx(curPointIdx, -1)
        }
        collectedPoints.push(curPointIdx)
      }

      // Same intersection?
      if (
        intersection1.line1.p1Idx === intersection2.line1.p1Idx ||
        intersection1.line1.p1Idx === intersection2.line1.p2Idx ||
        intersection1.line1.p1Idx === intersection2.line2.p1Idx ||
        intersection1.line1.p1Idx === intersection2.line2.p2Idx
      ) {
        // todo: remove collected points from available points
        // todo: add this loop
        // todo: return
      }
    }
  }
}

/**
 * Class that's capable of doing some operations to detangle a geometry
 */

/**
 * Geometry that's capable of dividing itself
 *
 * TODO: Would be nice to allow for "inner points", so that the fission can choose a path that's not a straight line through the center.
 *  - Any point along any line b/t edge points should be safe? (not necessarily)
 *
 */
class FisableGeometry {
  points: FisableEdgePoint[]

  constructor(geo: Geometry) {
    this.points = geo.map((p) => {
      return {
        point: p,
        isReal: true,
      }
    })
  }

  insertMidpionts = () => {
    const startingPoints = [...this.points]

    this.points = []
    for (let i = 0; i < startingPoints.length; i++) {
      const curPoint = startingPoints[i]
      const nextPoint = startingPoints[(i + 1) % startingPoints.length]
      const midPoint = R2.findPointsCenter([curPoint.point, nextPoint.point])

      // Add this point, and it's leading point
      this.points.push(curPoint)

      // NOTE where the midpoint index is here
      this.points.push({ point: midPoint, isReal: false })
    }
  }

  safeIdx = (idx: number) => {
    return (
      ((idx % this.points.length) + this.points.length) % this.points.length
    )
  }

  findRealNeighbor = (startIdx: number, counterClockwise = false) => {
    const walkingInc = counterClockwise ? -1 : 1

    // Walk forward from our point until you reach our point again.
    // (We start 1 point away from our starting point)
    let curIdx = this.safeIdx(startIdx + walkingInc)
    while (curIdx != startIdx) {
      // Have we reached something real?
      if (this.points[curIdx].isReal) {
        return curIdx
      }

      // Move along
      curIdx = this.safeIdx(curIdx + walkingInc)
    }

    // Found nothing!
    return null
  }

  // Find permissable edge destinations
  getFissionEdgeDestinations = (startingIdx: number) => {
    // Given the starting point, we're looking for WHERE we're allowed to stop.

    // Out of bounds items are
    // Everythings along the counter-clockwise walk to the next real point
    // Same for clockwise

    // Plan:
    // Walk forwards until you hit a point, and note it as the nearest real neighbor.
    // Walk backwards until you hit a point and note it as nearest real neighbor.
    // Find all points between the two

    // Walk forward from our point until you reach our point again.
    let clockwiseNeighbor = this.findRealNeighbor(startingIdx, false)
    let counterclockwiseNeighboar = this.findRealNeighbor(startingIdx, true)

    // Unable to find a real neighbor in either dir?
    if (clockwiseNeighbor === null || counterclockwiseNeighboar === null)
      return null

    // Now we can walk from 1 neighbor to the next :-)
    // We'll go clockwise. So from clockwise neighboor -> counterclockwise neighbor.
    // (counterclock neighbor -> clockwise neighbor are all the forbidden nodes, all the things very close to our start.)

    // NOTE: the nearest real neighbors are NOT included (they're the end of the line segment from the sart point :-)
    let curIdx = (clockwiseNeighbor + 1) % this.points.length
    let allowedPointIdxs = []
    while (curIdx !== counterclockwiseNeighboar) {
      allowedPointIdxs.push(curIdx)
      curIdx = this.safeIdx(curIdx + 1)
    }

    if (allowedPointIdxs.length === 0) return null

    return allowedPointIdxs
  }

  cutGeo = (geo: FisableEdgePoint[], idx1: number, idx2: number) => {
    // Start & end points will belong to both of the shapes.
    // Fold them in as we loop through the geo maybe?

    const lowerIdx = Math.min(idx1, idx2)
    const upperIdx = Math.max(idx1, idx2)

    // If we're less than the start ||  greater than end; geo1
    // If we're between start & end; geo2
    // If we equal a point; add to both

    const geo1: FisableEdgePoint[] = []
    const geo2: FisableEdgePoint[] = []

    for (let i = 0; i < geo.length; i++) {
      // Copy the point so we can mutate
      const point = { ...geo[i] }

      // Mark as real if this is a boundary (may be a phantom that we chose)
      if (i === lowerIdx || i === upperIdx) point.isReal = true

      // Add to the relevant geometries
      if (i <= lowerIdx || i >= upperIdx) {
        geo1.push(point)
      }
      if (i >= lowerIdx && i <= upperIdx) {
        geo2.push(point)
      }
    }

    return [geo1, geo2]
  }

  fission = () => {
    // A geometry needs at least 4 points in order to be cut in half.
    // Return null if there's not enough
    if (this.points.length < 4) return null

    // We're gonna cut the geo somewhere.

    // We need to pick a start point and an end point.
    const startPoint = Random.getRandomInt(0, this.points.length)

    // Get allowed destinations
    const allowedEndpoins = this.getFissionEdgeDestinations(startPoint)

    if (!allowedEndpoins) return null

    // Only constraint is that the endpoint CANNOT be next to the start point
    let endPoint: number = Random.randomChoice(allowedEndpoins)

    const newGeos = this.cutGeo(this.points, startPoint, endPoint).map(
      (geo) => {
        const filtered = geo.filter((point) => point.isReal)

        const formatted: Geometry = filtered.map((p) => p.point)

        return formatted
      },
    )

    return newGeos
  }
}

// Tesselate geometry function
export const tesselateGeometry = (geo: Geometry) => {
  // Each of the points of the geo is fair game.

  const divider = new FisableGeometry(geo)
  divider.insertMidpionts()
  return divider.fission()
}
