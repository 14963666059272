import { v4 as uuidv4 } from "uuid"
import { FidgetId } from "../../../constants/fidgetConsts"
import { EphemeralEngine } from "./EphemeralEngine"
import { R2 } from "../../../libs/Math/R2"
import { setAtomValue } from "../../../state/atomStore"
import {
  curSimInitTAtom,
  simHeightMeters,
  simWidthMeters,
} from "../../../state/sim"

// The Simulator will handle all logic for simulating

// - Visual animation

// - Audio Polling

// JS Library for Ripple Animations

export interface PointEvent {
  x: number
  y: number
  movement: {
    x: number
    y: number
  }
}

/** All entities that may be accessing or writing to the share state */
export enum SharedStateEntity {
  REACT,
  FIDGET,
}

/**
 * We'd like to be able to share data between the fidget and the react state....
 *
 * This is an interesting problem...
 * - Fidget should listen & hear react state updates when needed
 * - Fidget should be able to set react state.
 *
 * This object is expected to live... inside of the Fidget... living in react doesn't make THAT much sense.
 */
export class FidgetReactSharedState {
  // The state :-)
  // TODO @Marcel: Can we make this type defined for specific instances?
  private data: any = null

  // Function to set the state
  // TODO @Marcel: Similar note on stronger typing
  private sendDataToReact: ((data: any) => void) | null = null

  private fidgetId: FidgetId

  constructor(fidgetId: FidgetId) {
    // This is inherently already FidgetState. React has a way to write here.

    // TODO: Establish a pathway for Widget to write to React
    // - React needs to execute this.

    // Nothing to do initially. Need to establish pathways.

    this.fidgetId = fidgetId
  }

  /** Write to state
   *
   * NOTE: Writing will just write to the React sate.
   *
   * The SimCanvas will sync changes back down to this state.
   * This prevents double calls to the write function that get kinda wonky...
   *
   * OPTIMIZATION: Could speed up by setting the data early here :-) commented out now, to avoid this function calling twice and doing odd things.
   *
   * TODO @Marcel: Any runtime vars should be moved to configs inside the fidgets themselves, and linked at runtime to the config that's living.
   * (Things like "running calc of position", etc)
   *
   *
   * @Marcel Random note: having a start time cataloged and determinism is kinda cool!
   * Its like you can return and "check in" on what's happening now :-)
   *
   */
  write(newState: any, comingFrom: SharedStateEntity) {
    // this.data = newState;
    // console.warn("Coming From : ", comingFrom)

    // Inform any other entities if needed
    switch (comingFrom) {
      case SharedStateEntity.FIDGET:
        // console.warn("Writing to react: ", this.data)
        this.writeToReact({ fidgetId: this.fidgetId, data: newState })
        break

      case SharedStateEntity.REACT:
        this.data = newState
        break

      default:
        break
    }
  }

  /** Read from the state */
  read() {
    return this.data
  }

  /** To be called by React to establish a connection */
  reactInit(stateSetter: any) {
    this.sendDataToReact = stateSetter
  }

  private writeToReact(newState: any) {
    if (this.sendDataToReact) this.sendDataToReact(newState)
  }
}

let allowedId: string | null = null

export interface FidgetConfig {
  skipCtx?: boolean
  ignoreAudio?: boolean
}

export default class Fidget {
  // Properties
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D | null
  id: string
  ignoreAudio: boolean

  // Mouse
  mouseDown: boolean = false

  // Run Time
  running: boolean = true
  initT: number | null = null
  lastRenderT: number | null = null
  awaitingCanvasDims: boolean = true

  lastTouchLocation: { x: number; y: number } | null = null

  // Engines
  ephemeralEngines: EphemeralEngine[] = []

  // Physics
  world: CanvasWorld

  // TODO @Marcel: This is a hack, remove eventually!
  hardStopped: boolean = false
  failedRenderCounter = 0

  sharedState: FidgetReactSharedState

  // Debugger
  debugger: Debugger = new Debugger()

  // Functions

  constructor(
    canvas: HTMLCanvasElement,
    fidgetId: FidgetId,
    config: FidgetConfig = {},
  ) {
    // Save canvas & context
    this.canvas = canvas
    if (!config.skipCtx) this.ctx = canvas.getContext("2d")
    else this.ctx = null

    this.world = new CanvasWorld(this.canvas)

    this.ignoreAudio = Boolean(config.ignoreAudio)

    this.id = uuidv4()

    // Initialize a shared state
    this.sharedState = new FidgetReactSharedState(fidgetId)

    // Current button down
    this.mouseDown = false

    this.running = true
    this.lastRenderT = null

    // Attach canvas handlers (needed? )
    this.canvas.addEventListener("click", (e) => this.handleClickWrapper(e))
    this.canvas.addEventListener("mousemove", (e) =>
      this.handleMouseMoveWrapper(e),
    )
    this.canvas.addEventListener("mousedown", (e) =>
      this.handleMouseDownWrapper(e),
    )
    this.canvas.addEventListener("mouseleave", (e) =>
      this.handleMouseLeaveWrapper(e),
    )
    this.canvas.addEventListener("mouseup", (e) => this.handleMouseUpWrapper(e))
    this.canvas.addEventListener("touchmove", (e) =>
      this.handleMouseDragWrapper(e),
    )
    this.canvas.addEventListener("touchstart", (e) =>
      this.handleMouseDownWrapper(e),
    )
    this.canvas.addEventListener("touchend", (e) =>
      this.handleMouseUpWrapper(e),
    )

    // Bind functions
    this.animate = this.animate.bind(this)
    this.simulate = this.simulate.bind(this)
    this.stop = this.stop.bind(this)
    this.relCoordsFromE = this.relCoordsFromE.bind(this)
    this.handleClickWrapper = this.handleClickWrapper.bind(this)
    this.handleMouseMoveWrapper = this.handleMouseMoveWrapper.bind(this)
    this.handleMouseDownWrapper = this.handleMouseDownWrapper.bind(this)
    this.handleMouseLeaveWrapper = this.handleMouseLeaveWrapper.bind(this)
    this.handleMouseUpWrapper = this.handleMouseUpWrapper.bind(this)

    // TODO @Marcel: Handle all three of these using inheritance / overrides!
    // Save event hanlders (for process time)
    // this.handlers = handlers;

    // Attach render methods
    // this.renderFrame = renderFrame;
    // this.afterRender = afterRender;

    // Mount helper
    this.awaitingCanvasDims = this.canvas.width === 0 ? true : false

    const now = new Date()
    this.initT = now.getTime()
    setAtomValue(curSimInitTAtom, this.initT)

    // Start simulation!
    this.simulate()

    // TODO @Marcel: Remove hack
    allowedId = this.id
  }

  relCoordsFromE = (e: MouseEvent | any) => {
    let x = e.clientX
    let y = e.clientY
    let movement: { x: number; y: number } = { x: e.movementX, y: e.movementY }

    if (["touchmove", "touchstart", "touchend"].includes(e.type)) {
      const evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent
      const touch = evt.touches[0] || evt.changedTouches[0]
      x = touch.clientX
      y = touch.clientY
    }

    // Catalog movement on touchscreens
    if ("touchmove" && this.lastTouchLocation?.x) {
      movement = {
        x: x - this.lastTouchLocation.x,
        y: y - this.lastTouchLocation.y,
      }
    }

    // Store last move
    if ("touchstart") {
      this.lastTouchLocation = { x, y }
    }

    // Ensure safemovement
    movement.x = movement.x ?? 0
    movement.y = movement.y ?? 0

    // Get canvas coord
    const rect = this.canvas.getBoundingClientRect()

    let output: PointEvent = {
      x: x - rect.x,
      y: y - rect.y,
      movement,
    }

    return output
  }

  /**
   * Register any processes (like audio) that require user interaction as a pre-requisite
   */
  postTouchRegisterCallback = () => {}

  // -------- Event Handling Wrappers --------

  blockEvents = () => {
    return this.hardStopped
  }

  handleClickWrapper = (e: MouseEvent | any) => {
    if (this.blockEvents()) return

    e.preventDefault()
    const event = this.relCoordsFromE(e)

    this.onClick(event)

    // Ephemeral Engines
    for (let engine of this.ephemeralEngines) engine.onClick(event)
  }

  handleMouseMoveWrapper = (e: MouseEvent | any) => {
    if (this.blockEvents()) return

    e.preventDefault()
    const event = this.relCoordsFromE(e)

    if (this.mouseDown) {
      this.onDrag(event)
      // Ephemeral Engines
      for (let engine of this.ephemeralEngines) engine.onDrag(event)
    }

    this.onMove(event)

    // Ephemeral Engines
    for (let engine of this.ephemeralEngines) engine.onMouseMove(event)
  }

  handleMouseDragWrapper = (e: MouseEvent | any) => {
    if (this.blockEvents()) return

    e.preventDefault()
    const event = this.relCoordsFromE(e)
    this.onDrag({ ...event })

    // Ephemeral Engines
    for (let engine of this.ephemeralEngines) engine.onDrag(event)
  }

  handleMouseDownWrapper = (e: MouseEvent | any) => {
    if (this.blockEvents()) return

    this.mouseDown = true
    const event = this.relCoordsFromE(e)
    this.postTouchRegisterCallback()
    this.onMouseDown(event)
    // Ephemeral Engines
    for (let engine of this.ephemeralEngines) engine.onMouseDown(event)
  }

  handleMouseUpWrapper = (e: MouseEvent | any) => {
    if (this.blockEvents()) return

    this.mouseDown = false
    const event = this.relCoordsFromE(e)
    this.mouseUp(event)

    // Ephemeral Engines
    for (let engine of this.ephemeralEngines) engine.onMouseUp(event)
  }

  handleMouseLeaveWrapper = (e: MouseEvent | any) => {
    if (this.blockEvents()) return

    this.mouseDown = false
    const event = this.relCoordsFromE(e)
    this.onLeave(event)

    // Ephemeral Engines
    for (let engine of this.ephemeralEngines) engine.onMouseLeave(event)
  }

  // ------- Functions to override!!! -------

  onCanvasDimsLoad = () => {}
  private onCanvasDimsLoadInner = () => {
    this.world.onCanvasDimsLoad()
  }

  onClick = (e: PointEvent) => {
    // Override
  }

  onMove = (e: PointEvent) => {
    // Override
  }

  onDrag = (e: PointEvent) => {
    // Override
  }

  onMouseDown = (e: PointEvent) => {
    // Override
  }

  mouseUp = (e: PointEvent) => {
    // Override
  }

  onLeave = (e: PointEvent) => {
    // Override
  }

  renderFrame = async () => {}

  afterRender = () => {}

  // -------- Load / Save --------

  saveToObj = () => {
    // Override me :)
  }

  loadFromObj = () => {
    // Override me :)
  }

  simulate() {
    this.animate()
  }

  stop() {
    this.running = false
  }

  // Animate frame
  async animate() {
    if (!this.running) return

    if (this.hardStopped) return
    // TODO @Marcel: This doesn't work.
    // I need a better way of only allocating 1 simulator at a time, for sure....
    // How to do that?.... tricky.
    // TODO @Marcel: This is a really bad hack to lock anything else that isn't the most recent created Figdet controller.
    // This should be prevented at a much higher level, to ensure there is only one controller ever!!!!
    // PER COMPONENT TOO!
    // This will prevent multiple valid fidget windows from operating correclty.
    // But... this was introduced to unblock some root stuff.
    /**
     * The core of the issue is that the PluginPage will create 2 instances of the Sim, (it mounts - unmounts - mounts for some reason)
     * ... and not clean up the first when it unmounts.
     *
     * This leads to some issues.
     */
    if (this.id !== allowedId) {
      this.failedRenderCounter += 1
      console.log("Fail counter: ", this.id, this.failedRenderCounter)
      if (this.failedRenderCounter > 2) {
        this.hardStopped = true
        return
      }
    }

    // Canvas dims load?
    if (this.awaitingCanvasDims && this.canvas.width !== 0) {
      this.awaitingCanvasDims = false
      this.onCanvasDimsLoadInner()
      this.onCanvasDimsLoad()
    }

    if (this.debugger) this.debugger.onFrame()

    // Render from the fidget
    await this.renderFrame()

    // Render from any EphemeralEngines
    for (let engine of this.ephemeralEngines) await engine.renderFrame(this.ctx)

    this.afterRender()
    this.lastRenderT = Date.now()

    // Request frame
    window.requestAnimationFrame(this.animate)
  }

  clearCanvas() {
    if (!this.ctx) return
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }
}

export enum CanvasCoordSystems {
  /**
   * WORLD CANVAS SPACE
   *
   * "sim height in meters"
   * /\
   * |
   * |
   * |
   * |
   * |
   * 0-------------------> "sim width in meters"
   */
  WORLD,
  /**
   * WORLD CANVAS SPACE
   * canvas h in px
   * /\
   * |
   * |
   * |
   * |
   * |
   * 0-------------------> canvas w in px
   */
  WORLD_CANVAS,
  /**
   * CANVAS SPACE
   * 0-------------------> canvas w in px
   * |
   * |
   * |
   * |
   * |
   * \/
   *canvas h in px
   */
  CANVAS,
}

class CanvasWorld {
  canvas: HTMLCanvasElement

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas

    window.addEventListener("resize", (e) => {
      // TODO @MLEGROS: BRITTLE!!!!
      setAtomValue(
        simWidthMeters,
        this.canvasLengthToWorldLength(this.canvas.width),
      )
      setAtomValue(
        simHeightMeters,
        this.canvasLengthToWorldLength(this.canvas.height),
      )
    })
  }

  onCanvasDimsLoad() {
    // Finally declare a default world size
    // If window size changes...
    // ... i think the world will just stretch out with same pixel ratio which is cool
    this.declareCanvasHeightInMeters(10)

    // TODO @MLEGROS: BRITTLE!!!!
    setAtomValue(
      simWidthMeters,
      this.canvasLengthToWorldLength(this.canvas.width),
    )
    setAtomValue(
      simHeightMeters,
      this.canvasLengthToWorldLength(this.canvas.height),
    )
  }

  // ------- WORLD CONVERSIONS -------
  // todo: convert this out into it's own "world" helper class? (testability & modularity)

  /**
   * WORLD CANVAS SPACE
   * canvas h in px
   * /\
   * |
   * |
   * |
   * |
   * |
   * 0-------------------> canvas w in px
   *
   * to
   *
   * CANVAS SPACE
   * 0-------------------> canvas w in px
   * |
   * |
   * |
   * |
   * |
   * \/
   *canvas h in px
   */
  worldCanvasSpaceToCanvasSpace(point: R2.Vector): R2.Vector {
    return {
      x: point.x,
      y: this.canvas.height - point.y,
    }
  }

  /**
   * WORLD CANVAS SPACE
   *
   * "sim height in meters"
   * /\
   * |
   * |
   * |
   * |
   * |
   * 0-------------------> "sim width in meters"
   *
   * to
   *
   * CANVAS SPACE
   * 0-------------------> canvas w in px
   * |
   * |
   * |
   * |
   * |
   * \/
   * canvas h in px
   *
   */
  worldSpaceToCanvasSpace(p: R2.Vector) {
    // Scale
    const scaledToPixels = R2.mul(p, this.pixelsPerMeter)

    // Invert
    return this.worldCanvasSpaceToCanvasSpace(scaledToPixels)
  }

  getCenter(space: CanvasCoordSystems): R2.Vector {
    switch (space) {
      case CanvasCoordSystems.CANVAS: {
        return {
          x: this.canvas.width / 2,
          y: this.canvas.height / 2,
        }
      }
      case CanvasCoordSystems.WORLD_CANVAS: {
        // same
        return {
          x: this.canvas.width / 2,
          y: this.canvas.height / 2,
        }
      }
      case CanvasCoordSystems.WORLD: {
        return R2.mul(
          {
            x: this.canvas.width / 2,
            y: this.canvas.height / 2,
          },
          1 / this.pixelsPerMeter,
        )
      }
    }
  }
  getBounds(space: CanvasCoordSystems): R2.Vector {
    switch (space) {
      case CanvasCoordSystems.CANVAS: {
        return { x: this.canvas.width, y: this.canvas.height }
      }
      case CanvasCoordSystems.WORLD_CANVAS: {
        return { x: this.canvas.width, y: this.canvas.height }
      }
      case CanvasCoordSystems.WORLD: {
        return {
          x: this.canvasLengthToWorldLength(this.canvas.width),
          y: this.canvasLengthToWorldLength(this.canvas.height),
        }
      }
    }
  }

  worldLengthToCanvasLength(l: number) {
    return l * this.pixelsPerMeter
  }
  canvasLengthToWorldLength(l: number) {
    return l / this.pixelsPerMeter
  }

  pixelsPerMeter: number = 100 // default to a reasonable number, we override once canvas is available

  /**
   * Declare that the height of the canvas traverses "x" meters. Scale subsequent conversions accordingly
   */
  declareCanvasHeightInMeters(heightInMeters: number) {
    this.pixelsPerMeter = this.canvas.height / heightInMeters
  }

  /**
   * Declare that the width of the canvas traverses "x" meters. Scale subsequent conversions accordingly
   */
  declareCanvasWidthInMeters(widthInMeters: number) {
    this.pixelsPerMeter = this.canvas.width / widthInMeters
  }
}

class Debugger {
  counter = 0
  framesPerLog = 20
  callbacks: (() => void)[] = []

  onFrame() {
    this.counter += 1
  }

  shouldRenderLowFreqDebugLine() {
    return this.counter % this.framesPerLog === 0
  }
}
