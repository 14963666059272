import Fidget, {
  PointEvent,
  SharedStateEntity,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

export interface Bubble {
  breathPeriod: number

  maxRad: number

  initial: {
    t: number
  }

  current: {
    location: R2.Vector
    radius: number
    t: number
    color: string
  }

  // Running Vars
  radius?: number
}

export default class Bubbles extends Fidget {
  // Class members
  // bubbles: Bubble[] = [];
  dragTrigger: boolean = true

  // Bubble management
  addBubble(x: number, y: number, period: number, r: number, color: string) {
    // Create bubble and add to the list
    const bubble: Bubble = {
      // Breath period (s) (0 -> r -> 0)
      breathPeriod: period,

      maxRad: r,

      initial: {
        t: Date.now(),
      },

      current: {
        location: R2.newVector(x, y),
        radius: r,
        t: Date.now(),
        color: color,
      },
    }

    let bubbles: Bubble[] | null = this.sharedState.read()
    console.warn("Bubbles: ", bubbles)
    if (!bubbles) bubbles = []
    bubbles.push(bubble)
    console.warn("Pushing Bubbles: ", bubbles)
    this.sharedState.write(bubbles, SharedStateEntity.FIDGET)
    // this.bubbles.push(bubble);

    // TODO @Marcel: This is hacky. The state should all be in the sync state in the first place
    // this.sharedState.write([...this.bubbles], SharedStateEntity.FIDGET)
  }

  onDrag = (e: PointEvent) => {
    if (this.dragTrigger)
      this.addBubble(
        e.x,
        e.y,
        AyisenMath.Random.getRandomFloat(2, 10),
        AyisenMath.Random.getRandomInt(50, 100),
        AyisenMath.Random.randomColor(),
      )
  }

  onClick = (e: PointEvent) => {
    // Add bubble
    this.addBubble(
      e.x,
      e.y,
      AyisenMath.Random.getRandomFloat(2, 10),
      AyisenMath.Random.getRandomInt(50, 100),
      AyisenMath.Random.randomColor(),
    )
  }

  // Render Frame
  renderFrame = async () => {
    if (!this.ctx) return

    // Render / Update ripples
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    // Get meta breath multiplier
    // let metaT =  (Date.now() - this.simulator.initT) / 1000;
    // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
    // metaInterp = (metaInterp + 1) / 2;
    let metaInterp = 1

    const bubbles: Bubble[] = this.sharedState.read() ?? []

    bubbles.forEach((bubble, idx) => {
      // Set current radius
      const relT = (Date.now() - bubble.initial.t) / 1000
      let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod))

      // Range : -1 to 1 -> 0 to 1
      interpVal = (interpVal + 1) / 2
      interpVal *= metaInterp

      // Set Radus : range 0 to maxRad
      bubble.radius = bubble.maxRad * interpVal

      // Set opacity : range 0.2 to 0.8
      const opacity = interpVal * 0.6 + 0.2

      if (!this.ctx) return

      VisualEngine.canvas.drawBubble(
        this.ctx,
        bubble.current.location,
        bubble.radius,
        bubble.current.color,
        opacity,
      )
    })

    // Remove as needed.
    for (let idx of toRemove) {
      bubbles.splice(idx, 1)
      console.log("Removal")
    }
    if (toRemove.length > 0)
      this.sharedState.write(bubbles, SharedStateEntity.FIDGET)
  }
}

// export default class Bubbles {

//     constructor(canvas) {

//         // Save canvas & context
//         this.canvas = canvas;
//         this.ctx = canvas.getContext('2d');

//         this.dragTrigger = true;

//         // Attach canvas handlers

//         // Ripple management
//         this.currentBubbles = [];

//         // Bind functions
//         this.renderFrame = this.renderFrame.bind(this);
//         this.handleClick = this.handleClick.bind(this);
//         this.handleDrag = this.handleDrag.bind(this);

//         // Simulation handlers
//         const simHandlers = {
//             onClick: e => this.handleClick(e),
//             onDrag: e => this.handleDrag(e),
//         }

//         // Attach simulator
//         this.simulator = new Simulator(canvas, this.renderFrame, ()=> {}, simHandlers);
//         this.simulator.simulate();

//     }

//     }

//     // Animate frame
//     renderFrame () {

//         // Render / Update ripples

//         this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

//         const toRemove = [];

//         // Get meta breath multiplier
//         // let metaT =  (Date.now() - this.simulator.initT) / 1000;
//         // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
//         // metaInterp = (metaInterp + 1) / 2;
//         let metaInterp = 1;

//         this.currentBubbles.forEach((bubble, idx) => {

//             // Set current radius
//             const relT = (Date.now() - bubble.initial.t) / 1000;
//             let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod));

//             // Range : -1 to 1 -> 0 to 1
//             interpVal = (interpVal + 1) / 2;
//             interpVal *= metaInterp

//             // Set Radus : range 0 to maxRad
//             bubble.radius = bubble.maxRad * (interpVal)

//             // Set opacity : range 0.2 to 0.8
//             const opacity = (interpVal * 0.6) + 0.2

//             canvasUtils.drawBubble(
//                 this.ctx,
//                 {x: bubble.current.x, y: bubble.current.y},
//                 bubble.radius,
//                 bubble.current.color,
//                 opacity
//             );
//         });

//         // Remove as needed.
//         for (let idx of toRemove)
//             this.currentBubbles.splice(idx, 1);

//     }

// }
