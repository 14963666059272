import { useState, useEffect, useCallback } from "react"
import "./midi_keyboard.css"
import { randomColorNoAlpha } from "../../libs/Math/Random"
import { useAtom } from "jotai"
import {
  currentlyPlayingNotesAtom,
  midiSettingAtom,
} from "../../state/midi_keyboard_state"
import { samplePathAtom } from "../../state/sampleState"
import { SamplePathSegment } from "../../Engines/AudioEngine/ela/SampleReader"
import { AyisenSynth } from "../../Engines/AudioEngine/AyisenSynth/AyisenSynth"
import {
  PlayableNote,
  PlayableNoteList,
  getNoteIntervalUp,
} from "../../types/audio/note"
import { SampleSelector } from "../SampleSelector"

const noop = () => {}

export interface Key {
  color: "white" | "black"
  note: PlayableNote
  onClick: () => void
  keyboardShortcutKey?: string
}

/**
 * Keyboard shortcuts that will trigger different keys
 */
const keyboardShortcutsForNotes: { keyboardKey: string; note: PlayableNote }[] =
  [
    { keyboardKey: "a", note: "C4" },
    { keyboardKey: "w", note: "C4#" },
    { keyboardKey: "s", note: "D4" },
    { keyboardKey: "e", note: "D4#" },
    { keyboardKey: "d", note: "E4" },
    { keyboardKey: "f", note: "F4" },
    { keyboardKey: "t", note: "F4#" },
    { keyboardKey: "g", note: "G4" },
    { keyboardKey: "y", note: "G4#" },
    { keyboardKey: "h", note: "A4" },
    { keyboardKey: "u", note: "A4#" },
    { keyboardKey: "j", note: "B4" },
    { keyboardKey: "k", note: "C5" },
  ]

const generateKeyboardConfig = (
  numOctavesDown: number,
  numOctavesUp: number,
  root: PlayableNote = "C4",
) => {
  // Walk up or down the octave.

  // Here's a map of what to do when you encounter certain semitones. Only 1 octave down / up needed
  // We can base it on the positive octave. we'll %12, and if the num is <0.... then we can add 12 to it to find it's mod number.
  const semiTonesToKeyColor: { [key: number]: "white" | "black" } = {
    0: "white",
    1: "black",
    2: "white",
    3: "black",
    4: "white",

    5: "white",
    6: "black",
    7: "white",
    8: "black",
    9: "white",
    10: "black",
    11: "white",
  }

  const config: Key[] = []

  // Root from which we'll expand the keyboard out
  const rootNoteIdx = PlayableNoteList.findIndex(
    (e: PlayableNote) => e === root,
  )

  if (rootNoteIdx === -1) return config

  for (let octave = -numOctavesDown; octave < numOctavesUp; octave++) {
    const semitoneOffset = octave * 12

    for (let semitone = 0; semitone < 12; semitone++) {
      const note: PlayableNote =
        PlayableNoteList[rootNoteIdx + semitoneOffset + semitone]

      const keyboardShortcutFound = keyboardShortcutsForNotes.find(
        (e) => e.note === note,
      )

      // Semitone for this level
      config.push({
        color: semiTonesToKeyColor[semitone],
        note,
        onClick: noop,
        keyboardShortcutKey: keyboardShortcutFound?.keyboardKey,
      })
    }
  }

  return config
}

const keyConfig: Key[] = generateKeyboardConfig(1, 1)

export default ({
  onKeyClick,
  onKeyUp,
  synth,
}: {
  onKeyClick?: (keys: Key[]) => void
  onKeyUp?: (keys: Key[]) => void
  // Preferrably: just provide it with a synth to control :-)
  synth?: AyisenSynth // not stateful; only meant to trigger callbacks to audio
}) => {
  const [keyColor, _] = useState<string>(randomColorNoAlpha())

  const [midiConfig, setMidiConfig] = useAtom(midiSettingAtom)
  const [samplePath, setSamplePath] = useAtom(samplePathAtom)
  const [currentlyPlayingNotes, setCurrentlyPlayingNotes] = useAtom(
    currentlyPlayingNotesAtom,
  )

  const [renderControls, setRenderControls] = useState(false)

  const handleKeyClick = useCallback(
    (key: Key) => {
      const keys = getRelevantKeys(key)

      // Callback
      if (onKeyClick) onKeyClick(keys)

      // Compile a list of all prev notes + the new ones, and set for visuals
      const newCurrentlyPlayingNotes = [...currentlyPlayingNotes]
      for (let newKey of keys) {
        if (!currentlyPlayingNotes.includes(newKey.note))
          newCurrentlyPlayingNotes.push(newKey.note)
      }
      setCurrentlyPlayingNotes(newCurrentlyPlayingNotes)

      // if (synth) {
      //   synth.triggerNotes(keysToNotes(keys))
      // }
      if (synth) {
        // todo: we should only need to trigger new things. Old things should continue on
        synth.triggerNotes(newCurrentlyPlayingNotes)
      }
    },
    [currentlyPlayingNotes, setCurrentlyPlayingNotes, onKeyClick],
  )

  const handleKeyUp = useCallback(
    (key: Key) => {
      const keys = getRelevantKeys(key)

      // Callback
      if (onKeyUp) onKeyUp(keys)

      if (currentlyPlayingNotes.includes(key.note))
        setCurrentlyPlayingNotes(
          currentlyPlayingNotes.filter((note) => note !== key.note),
        )

      // Synth
      if (synth) {
        synth.releaseNotse(keysToNotes(keys))
      }
    },
    [currentlyPlayingNotes, setCurrentlyPlayingNotes, onKeyUp],
  )

  const keyDownListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return
      const noteToPlay = keyboardShortcutsForNotes.find(
        (elem) => elem.keyboardKey === e.key,
      )?.note
      if (noteToPlay)
        handleKeyClick({
          color: "white" /**doesnt matter */,
          note: noteToPlay,
          onClick: noop,
        })
    },
    [handleKeyClick],
  )
  const keyUpListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return
      const noteToEnd = keyboardShortcutsForNotes.find(
        (elem) => elem.keyboardKey === e.key,
      )?.note
      if (noteToEnd)
        handleKeyUp({
          color: "white" /**doesnt matter */,
          note: noteToEnd,
          onClick: noop,
        })
    },
    [handleKeyUp],
  )

  // Mount / unmount keyboard handling
  useEffect(() => {
    window.addEventListener("keypress", keyDownListener)
    window.addEventListener("keyup", keyUpListener)

    return () => {
      window.removeEventListener("keypress", keyDownListener)
      window.removeEventListener("keyup", keyUpListener)
    }
  })

  const getRelevantKeys = (key: Key): Key[] => {
    if (midiConfig.mode === "note") {
      return [key]
    } else if (midiConfig.mode === "major-chord") {
      const list = [key]
      const thirdUp = getNoteIntervalUp(key.note, 4)
      if (thirdUp) list.push({ ...key, note: thirdUp })
      const fifthUp = getNoteIntervalUp(key.note, 7)
      if (fifthUp) list.push({ ...key, note: fifthUp })
      return list
    }
    return []
  }

  const keysToNotes = (keys: Key[]): PlayableNote[] => {
    return keys.map((key) => {
      return key.note
    })
  }

  return (
    <div className="midiCenterer">
      <div className="midiKeyboard">
        {keyConfig.map((keyEntry, idx) => {
          if (keyEntry.color === "white")
            return (
              <WhiteKey
                onClick={() => handleKeyClick(keyEntry)}
                onMouseUp={() => handleKeyUp(keyEntry)}
                isPressed={currentlyPlayingNotes.includes(keyEntry.note)}
                shortcut={keyEntry.keyboardShortcutKey}
              />
            )
          else
            return (
              <BlackKey
                keyColor={keyColor}
                onClick={() => handleKeyClick(keyEntry)}
                onMouseUp={() => handleKeyUp(keyEntry)}
                isPressed={currentlyPlayingNotes.includes(keyEntry.note)}
                shortcut={keyEntry.keyboardShortcutKey}
              />
            )
        })}
      </div>

      <img
        onClick={() => {
          setRenderControls(!renderControls)
        }}
        src={"/resources/icons/settings.png"}
        className="settingsIcon"
      />

      {renderControls && (
        <div className="controlPanel">
          <label>
            <input
              type="checkbox"
              checked={midiConfig.mode === "major-chord"}
              onChange={(e) => {
                setMidiConfig({
                  ...midiConfig,
                  mode: midiConfig.mode === "note" ? "major-chord" : "note",
                })
              }}
            />
            Chord Mode
          </label>

          <SampleSelector />

          <h3>Sample Path</h3>
          {samplePath.segments.map((segment, idx) => {
            const updateSamplePath = (update: SamplePathSegment) => {
              const newSamplePath = { ...samplePath }
              newSamplePath.segments = [...samplePath.segments]
              newSamplePath.segments[idx] = update

              setSamplePath(newSamplePath)
            }

            const onRemove = () => {
              const newSamplePath = { ...samplePath }
              newSamplePath.segments = []
              for (let i = 0; i < samplePath.segments.length; i++) {
                if (i !== idx)
                  newSamplePath.segments.push(samplePath.segments[i])
              }
              setSamplePath(newSamplePath)
            }

            return (
              <SamplePath
                key={idx}
                samplePath={segment}
                onUpdate={updateSamplePath}
                onRemove={onRemove}
              />
            )
          })}
          <button
            className="addPathSegmentButton"
            onClick={() => {
              const newSamplePath = { ...samplePath }
              newSamplePath.segments.push({
                startLoc: 0,
                endLoc: 1,
              })
              setSamplePath(newSamplePath)
            }}
          >
            Add
          </button>

          {/* <button /> */}
        </div>
      )}
    </div>
  )
}

function SamplePath(props: {
  samplePath: SamplePathSegment
  onUpdate: (update: SamplePathSegment) => void
  onRemove: () => void
}) {
  const onUpdateStartLoc = (value: string) => {
    const num: number = Number(value)
    if (isNaN(num)) return

    props.onUpdate({ ...props.samplePath, startLoc: num })
  }

  const onUpdateEndLoc = (value: string) => {
    const num: number = Number(value)
    if (isNaN(num)) return

    props.onUpdate({ ...props.samplePath, endLoc: num })
  }

  const onUpdateManualPosition = (value: string) => {
    let num: number | undefined = Number(value)
    if (isNaN(num)) num = undefined

    props.onUpdate({ ...props.samplePath, placementPercent: num })
  }

  return (
    <div className="samplePathRow">
      <p>Start Loc</p>{" "}
      <input
        type="number"
        value={props.samplePath.startLoc}
        onChange={(e) => onUpdateStartLoc(e.target.value)}
      />
      <p>End Loc</p>{" "}
      <input
        type="number"
        value={props.samplePath.endLoc}
        onChange={(e) => onUpdateEndLoc(e.target.value)}
      />
      <p>Manual position</p>{" "}
      <input
        type="number"
        value={props.samplePath.placementPercent}
        onChange={(e) => onUpdateManualPosition(e.target.value)}
      />
      <button onClick={props.onRemove}>remove</button>
    </div>
  )
}

/**
 * TODO: For some reason, pointerUp is firing super super late.
 * -> hunch; it's only when audio playback is active. audio thread is blocking some callbacks for sec
 * OnMouseUp is getting triggered all the time all over the place.... might need to detatch this for now.
 */
const WhiteKey = ({
  onClick,
  onMouseUp,
  isPressed,
  shortcut,
}: {
  onClick: () => void
  onMouseUp: () => void
  isPressed: boolean
  shortcut?: string
}) => {
  // TODO: handle mouse lease if mosue is down at the time of leave
  return (
    <div
      className={"keyboardKey whiteKey" + (isPressed ? " pressedKey" : "")}
      onPointerDown={onClick}
      onPointerUp={onMouseUp}
    >
      {shortcut}
    </div>
  )
}

const BlackKey = ({
  onClick,
  onMouseUp,
  keyColor,
  isPressed,
  shortcut,
}: {
  onClick: () => void
  keyColor: string
  onMouseUp: () => void
  isPressed: boolean
  shortcut?: string
}) => {
  // TODO: handle mouse lease if mosue is down at the time of leave

  return (
    <div
      className={"keyboardKey blackKey" + (isPressed ? " pressedKey" : "")}
      style={{ backgroundColor: keyColor }}
      onPointerDown={onClick}
      onPointerUp={onMouseUp}
    >
      {shortcut}
    </div>
  )
}
