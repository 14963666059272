export namespace R1 {
  export interface Range {
    min: number
    max: number
  }

  export const getNumberSign = (input: number) => {
    return input / Math.abs(input)
  }

  export const approxEqual = (n1: number, n2: number, decimals: number) => {
    return Math.abs(n1 - n2).toFixed(decimals) === (0).toFixed(decimals)
  }

  export const interpolate = (x1: number, x2: number, t: number) => {
    // y= mx + b :-)

    if (t > 1) return x2
    if (t < 0) return x1

    return x1 + (x2 - x1) * t
  }

  /**
   * A true modulo operator
   * Note that in many programming languages, the % operator is a remainder operator (with result the same sign as the dividend), not a modulo operator; the modulo operation can be obtained by using ((x % p) + p) % p in place of x % p. In e.g. JavaScript, this results in an equation of the form 4*a/p * Math.abs((((x - p/4) % p) + p) % p - p/2) - a.
   * https://en.wikipedia.org/wiki/Triangle_wave
   */
  export function modulo(a: number, b: number) {
    return ((a % b) + b) % b
  }

  export function triangleWave(
    t: number,
    f: number,
    a: number,
    baseline: number,
  ) {
    const p = 1 / f

    const modTimeTerm = modulo(t - p / 4, p)
    const absValTerm = Math.abs(modTimeTerm - p / 2)
    const triangle = (4 * a * absValTerm) / p

    const baselineOffset = baseline

    return triangle + baselineOffset
  }

  export const computeRangeOverlap = (r1: Range, r2: Range) => {
    /**
     * There are a couple cases to keep in mind:
     *
     * 1. r1 could be lower bound
     * r1:  --------
     * r2:      ---------
     *
     * 2. r2 could be lower bound
     * r1:    --------
     * r2:  -------
     *
     * 3. One could be inside the other
     * r1:  -------------------
     * r2:      ---------
     *
     * ALSO: The entire thing could be happening in Negative space.
     *
     * All we really care about though is that we identify the cross over.
     * - Identify the lowest low bound.
     * - Progress to the other low bound.
     * - Begin counting
     * - Exit once we reach either upper bound.
     *
     */

    const higherLowBound = Math.max(r1.min, r2.min)
    const lowerHighBound = Math.min(r1.max, r2.max)

    return lowerHighBound - higherLowBound
  }
}
