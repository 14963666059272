import Fidget, {
  FidgetConfig,
  PointEvent,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import { GraphConnectionMap, GraphNodeMap } from "../../libs/Math/Graph"
import { R2 } from "../../libs/Math/R2"
import { FidgetId } from "../../constants/fidgetConsts"
import VisualEngine from "../../Engines/VisualEngine"

// JS Library for Ripple Animations

export default class Spider extends Fidget {
  nodes: GraphNodeMap = {}
  connectionGraph: GraphConnectionMap = {}

  constructor(canvas: HTMLCanvasElement, config: FidgetConfig = {}) {
    super(canvas, FidgetId.SPIDER, config)

    // Init nodes and connects
    this.initNodes()
    this.initConnections()
  }

  initNodes() {
    // Pick many nodes at random

    this.nodes = AyisenMath.Graph.generateRandomNodes(50, 60)
  }

  addConnection(uid1: string, uid2: string) {
    AyisenMath.Graph.addConnection(uid1, uid2, this.connectionGraph)
  }

  initConnections() {
    this.connectionGraph = AyisenMath.Graph.generateRandomConnections(
      this.nodes,
    )
  }

  onDrag = (e: PointEvent) => {
    // Add energy to nodes!
    for (let nodeId of Object.keys(this.nodes)) {
      // Dist
      const dist = AyisenMath.R2.distance(
        R2.newVector(
          this.nodes[nodeId].loc.x * this.canvas.width,
          this.nodes[nodeId].loc.y * this.canvas.height,
        ),
        R2.newVector(e.x, e.y),
      )

      // Give energy inversely proportional to distance!

      // TODO @Marcel: Clean up
      // TODO @Marcel: Better units for energy, etc.

      // Normalize the distance moved, so that moving 1/10 of the screen will add 0.1 KE
      const normalizedDrag = R2.norm(
        R2.newVector(
          e.movement.x / this.canvas.width,
          e.movement.y / this.canvas.height,
        ),
      )
      let normalizedMove = normalizedDrag.mag / 0.1
      normalizedMove *= 0.05

      const energyBump = normalizedMove / Math.pow(dist, 1.25)

      // Now... i also want to be bumping this in the direction pulled...
      // I want to add the energy in the direction of the normalized drag
      // So I'll increase the velocity along that unit vector, scaled by this energy hop.
      const velocityBump = Math.sqrt(2 * energyBump)
      const velocityUpdate = {
        x: normalizedDrag.norm.x * velocityBump,
        y: normalizedDrag.norm.y * velocityBump,
      }
      if (this.nodes[nodeId].loc.x < 1 && this.nodes[nodeId].loc.x > 0) {
        // console.log("Velocity Bump", velocityBump)
        // console.log("Normalized Drag", normalizedDrag)
        // console.log("Velocty Update", velocityUpdate)
      }

      this.nodes[nodeId].velocity = R2.newVector(
        this.nodes[nodeId].velocity.x + velocityUpdate.x,
        this.nodes[nodeId].velocity.y + velocityUpdate.y,
      )
    }
  }

  // Animate frame
  renderFrame = async () => {
    if (!this.ctx) return

    // TODO @Marcel: Move this to the simulation properties!
    let smallScreen = false
    if (this.canvas.width < 500) {
      smallScreen = true
    }

    // Render / Update ripples

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const toRemove: number[] = []

    // Get meta breath multiplier
    // let metaT =  (Date.now() - this.simulator.initT) / 1000;
    // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
    // metaInterp = (metaInterp + 1) / 2;
    let metaInterp = 1

    Object.values(this.nodes).forEach((node, idx) => {
      if (!this.ctx || !this.lastRenderT) return

      VisualEngine.canvas.drawBubble(
        this.ctx,
        R2.newVector(
          node.loc.x * this.canvas.width,
          node.loc.y * this.canvas.height,
        ),
        smallScreen ? 3 : 5,
        "rgba(255, 0, 0, 0.8)",
        1,
      )

      const newBody = AyisenMath.Body.updateBodyLoc(
        { location: node.loc, velocity: node.velocity, width: 0 },
        this.lastRenderT,
        0.005,
        0,
      )
      this.nodes[node.uid].loc = newBody.location
      this.nodes[node.uid].velocity = newBody.velocity
    })

    Object.keys(this.connectionGraph).forEach((nodeId) => {
      // For each connection...
      Object.keys(this.connectionGraph[nodeId]).forEach((connectedNodeId) => {
        if (!this.ctx) return

        // Draw line!
        VisualEngine.canvas.drawLine(
          this.ctx,
          R2.newVector(
            this.nodes[nodeId].loc.x * this.canvas.width,
            this.nodes[nodeId].loc.y * this.canvas.height,
          ),
          R2.newVector(
            this.nodes[connectedNodeId].loc.x * this.canvas.width,
            this.nodes[connectedNodeId].loc.y * this.canvas.height,
          ),
          2,
          smallScreen ? "rgba(0, 0, 255, 0.1)" : "rgba(0, 0, 255, 0.2)",
        )
      })
    })
  }
}
