import Quadratic from "./Quadratic"
import { R2 } from "./R2"
import { R1 } from "./R1"
import Random from "./Random"
import Graph from "./Graph"
import Body from "./Body"
import Line from "./Line"

export default {
  Quadratic,
  R2,
  R1,
  Random,
  Graph,
  Body,
  Line,
}
