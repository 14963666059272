// import Rippler from '../libs/fidget/rippler/Rippler';
// import Geo from '../libs/fidget/geo/GeoSequencer';
// import Flinger from '../libs/fidget/flinger';
// import Paths from '../libs/fidget/paths';
// import Bubbles from '../libs/fidget/bubbles';
// import Tangent from '../libs/fidget/tangent';
// import Spider from '../libs/fidget/spider';
// // import Paths2 from '../libs/fidget/paths2';
// // import Shimmer from '../libs/fidget/shimmer';

import { Shatter } from "../Instruments/Shatter"
import { Sine } from "../Instruments/Sine"
import Fidget from "../Engines/VisualEngine/Fidget/Fidget"
import Fidgets from "../Fidgets"
import { FidgetId } from "./fidgetConsts"
import { Bounce } from "../Instruments/Bounce"

// // Config for auto mapping fidgets! Auto nav, etc.

export enum FidgetType {
  TOY,
  INSTRUMENT,
}

export interface LinkInfo {
  display: string
  link: string
}

export interface RichLinkInfo extends LinkInfo {
  type: FidgetType
}

export interface FidgetInfo {
  fidgetId: FidgetId
  linkInfo: LinkInfo
  attachNewSimulator: (canvas: HTMLCanvasElement) => Fidget // TODO @Marcel: Specify further

  canBeSaved: boolean
}

export interface InstrumentInfo {
  instrumentId: string
  linkInfo: LinkInfo
  // for instruments; there's interactable web-ui. so we need to render a rich, custom component
  render: () => JSX.Element
}

export const getConfigById = (id: FidgetId) => {
  const fidgetConfig = fidgets.find((elem) => {
    return elem.fidgetId === id
  })

  return fidgetConfig ?? null
}

export const instruments: InstrumentInfo[] = [
  {
    instrumentId: FidgetId.SHATTER,
    linkInfo: {
      display: "SHATTER",
      link: "/shatter",
    },
    render: Shatter,
  },
  {
    instrumentId: FidgetId.SHATTER, //
    linkInfo: {
      display: "SINE",
      link: "/sine",
    },
    render: Sine,
  },
  {
    instrumentId: FidgetId.BOUNCE,
    linkInfo: {
      display: "BOUNCE",
      link: "/bounce_instrument",
    },
    render: Bounce,
  },
]

/**
 * Map from fidgetId to fidget configuration.
 *
 */
const fidgets: FidgetInfo[] = [
  {
    fidgetId: FidgetId.RIPPLE,
    linkInfo: {
      display: "RIPPLE",
      link: "/ripple",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Ripple(canvas)
    },
    // No relevant data, all ephemeral
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.BUBBLES,
    linkInfo: {
      display: "BUBBLES",
      link: "/bubbles",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Bubbles(canvas, FidgetId.BUBBLES, {
        ignoreAudio: true,
      })
    },
    canBeSaved: true,
  },
  {
    fidgetId: FidgetId.FLINGER,
    linkInfo: {
      display: "FLINGER",
      link: "/flinger",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Flinger(canvas, FidgetId.FLINGER, {
        ignoreAudio: true,
      })
    },
    // TODO
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.PATHS,
    linkInfo: {
      display: "PATHS",
      link: "/paths",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Paths(canvas, { ignoreAudio: true })
    },
    canBeSaved: true,
  },
  {
    fidgetId: FidgetId.TANGENT,
    linkInfo: {
      display: "TANGENT",
      link: "/tangent",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Tangent(canvas, { ignoreAudio: true })
    },
    // Ephemeral
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.SPIDER,
    linkInfo: {
      display: "SPIDER",
      link: "/spider",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Spider(canvas, { ignoreAudio: true })
    },
    // Ephemeral
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.PHYSICS,
    linkInfo: {
      display: "PHYSICS_DEMO",
      link: "/physics",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Physics(canvas, FidgetId.PHYSICS, {
        ignoreAudio: true,
      })
    },
    // Ephemeral
    canBeSaved: false,
  },
  // TMP BROKEN WHILE MAKING OSCI SAVE FRIENDLY, RIP
  // {
  //     fidgetId: FidgetId.BUNGEE,
  //     linkInfo: {
  // display: "BUNGEE",
  //     link: '/bungee',
  // },
  //     attachNewSimulator: (canvas: HTMLCanvasElement) => {
  //         return new Fidgets.BungeeFidget(canvas);
  //     },
  //     // Ephemeral
  //     canBeSaved: false,
  // },
  // {
  //     fidgetId: FidgetId.SONIC,
  //     linkInfo: {
  // display: "SONIC",
  //     link: '/sonic',
  // },
  //     attachNewSimulator: (canvas: HTMLCanvasElement) => {
  //         return new Fidgets.Sonic(canvas);
  //     },
  //     // Ephemeral
  //     canBeSaved: false,
  //     type: FidgetType.INSTRUMENT,
  // },
  // {
  //     fidgetId: FidgetId.SONIC_FLINGER,
  //     linkInfo: {
  //         display: "SONIC_FLINGER",
  //         link: '/sonic_flinger',
  //     },
  //     attachNewSimulator: (canvas: HTMLCanvasElement) => {
  //         return new Fidgets.SonicFlinger(canvas, FidgetId.SONIC_FLINGER);
  //     },
  //     canBeSaved: false,
  //     type: FidgetType.INSTRUMENT,
  // },
  {
    fidgetId: FidgetId.OSCI,
    linkInfo: {
      display: "OSCI",
      link: "/oscillator",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Oscillator(canvas, { ignoreAudio: true })
    },
    canBeSaved: true,
  },
  {
    fidgetId: FidgetId.HADRON,
    linkInfo: {
      display: "HADRON",
      link: "/hadron",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Hadron(canvas, { ignoreAudio: true })
    },
    // Ephemeral
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.FURBALL,
    linkInfo: {
      display: "FURBALL",
      link: "/furball",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Furball(canvas, { ignoreAudio: true })
    },
    // Ephemeral
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.FISSION,
    linkInfo: {
      display: "FISSION",
      link: "/fission",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Fission(canvas, FidgetId.FISSION, {
        ignoreAudio: true,
      })
    },
    canBeSaved: false,
  },
  {
    fidgetId: FidgetId.AMOEBA,
    linkInfo: {
      display: "AMOEBA",
      link: "/amoeba",
    },
    attachNewSimulator: (canvas: HTMLCanvasElement) => {
      return new Fidgets.Amoeba(canvas, FidgetId.AMOEBA, { ignoreAudio: true })
    },
    canBeSaved: false,
  },
]

/**
 * Aggregate all the relevant links we can have a user navigate to
 */
export const getLinkInformation = () => {
  const links: RichLinkInfo[] = [
    ...fidgets.map((fidget) => {
      return {
        ...fidget.linkInfo,
        type: FidgetType.TOY,
      }
    }),
    ...instruments.map((instrument) => {
      return {
        ...instrument.linkInfo,
        type: FidgetType.INSTRUMENT,
      }
    }),
  ]

  return links
}

export default fidgets
