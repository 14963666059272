import { R2 } from "./R2"

export interface MotableDelta {
  // Delta to apply
  delta: R2.Vector

  // When motion begins in epoch time
  startT: number

  // Duration (MS)
  duration: number
}

export interface CurrentMotionState {
  delta: R2.Vector
  done: boolean
}

export const getCurMotionValueu: (delta: MotableDelta) => CurrentMotionState = (
  delta: MotableDelta,
) => {
  // Get time now
  const now = Date.now()
  const elapsed = now - delta.startT
  if (elapsed > delta.duration) {
    return {
      done: true,
      delta: delta.delta,
    }
  }

  // Not started
  if (now < delta.startT) {
    return {
      done: false,
      delta: R2.newVector(0, 0),
    }
  }

  // Somewhere between
  const currentOffset = R2.interpBetweenPoints(
    R2.newVector(0, 0),
    delta.delta,
    elapsed / delta.duration,
  )
  return {
    done: false,
    delta: currentOffset,
  }
}
