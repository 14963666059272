import AyisenMath from "../../libs/Math"
import { R2 } from "../../libs/Math/R2"
import { PointEvent } from "../../Engines/VisualEngine/Fidget/Fidget"
import { EphemeralEngine } from "../../Engines/VisualEngine/Fidget/EphemeralEngine"
import canvasUtils from "../../Engines/VisualEngine/canvas/canvas"

export interface Ripple {
  // Growth rate = change in Radius / s
  growthRate: number
  duration: number

  initial: {
    location: R2.Vector
    radius: number
    width: number
    t: number
    color: string
  }
  current: {
    location: R2.Vector
    radius: number
    width: number
    t: number
    color: string
  }
}

export class RippleOnDrag extends EphemeralEngine {
  ripples: Ripple[] = []
  dragTrigger = true

  onClick(e: PointEvent): void {
    // Add ripple
    this.addRipple(e.x, e.y, 5, 100, AyisenMath.Random.randomColor())
  }

  onDrag(e: PointEvent): void {
    if (this.dragTrigger)
      this.addRipple(e.x, e.y, 5, 100, AyisenMath.Random.randomColor())
  }

  // Ripple management
  addRipple(x: number, y: number, w: number, r: number, color: string) {
    // Create ripple and add to the list
    const ripple: Ripple = {
      // Growth rate = change in Radius / s
      growthRate: 40,
      duration: 2,

      initial: {
        location: R2.newVector(x, y),
        radius: r,
        width: w,
        t: Date.now(),
        color: color,
      },
      current: {
        location: R2.newVector(x, y),
        radius: r,
        width: w,
        t: Date.now(),
        color: color,
      },
    }

    this.ripples.push(ripple)
  }

  async renderFrame(ctx: CanvasRenderingContext2D | null) {
    if (!ctx) return

    const canvas = ctx.canvas

    // Render / Update ripples

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const toRemove: number[] = []

    this.ripples.forEach((ripple: Ripple, idx: number) => {
      // Update radius
      const relT = (Date.now() - ripple.initial.t) / 1000
      ripple.current.radius = ripple.initial.radius + relT * ripple.growthRate
      const interp = relT / ripple.duration
      const fadeOut = 1 - interp

      if (interp >= 1) {
        toRemove.push(idx)
        return
      }

      if (!ctx) return

      canvasUtils.drawRipple(
        ctx,
        ripple.current.location.x,
        ripple.current.location.y,
        ripple.current.radius,
        ripple.current.width,
        ripple.current.color,
        fadeOut,
      )
    })

    // Remove as needed.
    for (let idx of toRemove) {
      this.ripples.splice(idx, 1)
    }
  }
}
