import { el, NodeRepr_t } from "@elemaudio/core"
import AudioCore from "../core/AudioCore"
import { sampleInfoToSignal } from "../sampling/SampleHandler"
import { v4 as uuidv4 } from "uuid"
import utilFunctions from "./utils"
import { SamplePath, SampleReader, SubSampleReader } from "./SampleReader"
import { SampleInfo } from "./sample/types"
import { getAtomValue } from "../../../state/atomStore"
import {
  activeSampleAtom,
  availableSamplesAtom,
} from "../../../state/sampleState"

const ela = {
  ...utilFunctions,

  pitchedDelay(
    delayTime: number,
    signal: any,
    frequencyMul: number | NodeRepr_t = 1,
  ) {
    const delayTimeMS = delayTime * 1000

    // We want to move the head @ a rate proportional to the shift we're applying
    // 0 shift :: don't move at all!
    // 1 shift :: don't move at all!
    // 2 shift :: move backwards at read-rate.
    // 3 shift :: move backwards such that total speed is 3 X normal speed

    /**
     * Really, the thing is we have two heads moving.
     *
     * - Normal push forward
     * - Our movement of the read-head
     *
     * We're controlling the value of "how long will we take to squash this whole array?"
     *
     * If we take the DURATION OF THE INPUT BUFFER LENGTH to squash it... we're moving at 2X speed.
     *
     * We're always moving at 1X speed by default.
     *
     * We move however much faster when we move the readhead.
     *
     *
     */

    // Read speed is 1 by default... this is how fast data is getting run through the read-head
    // units = (# times data played / delay-line-buffer time)
    let readSpeed = 1

    // However, we want to augment how fast data is getting run through the read-head.
    // So, we can move the read-head towards the data :-)

    // How fast should we do that?
    // This value is how fast we WANT the data to move through (compared to how fast it is)
    let targetReadSpeed = frequencyMul

    // Lets get the "additional speed needed"
    // units = (# time we need to move the read-head across the buffer / delay-line-buffer-time)
    let readHeadSpeed = el.sub(targetReadSpeed, readSpeed)

    /**
     * This should amount to
     *
     * 0 speed for the readHead when we freqMod = 1
     * 1 speed for the readHead when we freqMod = 2
     * 2 speed for the readHead when we freqMod = 3
     * -2 speed for the readHead when we freqMod =
     * ... etc.
     */

    // How long (in seconds) will it take for our moving read-head to cover the entire delay line?
    // Our readHeadSpeed tells us how many times we want to move the readHead across the delay-line, during 1 delay-line duration.
    // Lets compute the time it should take for the read head to close based on the fact that we need it to pass over the whole delay-line that many times.
    let timePerReadHeadPass = el.div(delayTime, readHeadSpeed)

    // Great, ^ that should give us the amount of time we should take to push the read-head all the way to the start.

    // Given the read head speed,... lets construct the read-head mover.
    const { value: readSizeAdjuster, phasor } = this.interp(
      delayTimeMS,
      0,
      timePerReadHeadPass,
    )

    const delay = el.delay(
      // TODO @Marcel: It might be useful to play with the size of the delay line too!
      // It might be useful to expand or shrink the size based on the freqMul?...
      // The only thing is,... there's no guarentee that some sound that plays will be picked up by the delay circuit at its start.
      // Or should that be faitly guarenteed?
      // *** For some reason, we're hopping around between different areas of the delay, and I want to figure out why.
      // *** I think this is most likely due to the SIGNAL LENGTH exceeding the delayTime.
      // *** This is not something we can really account for.
      // TODO @Marcel: Concretely figure out the difference between delay-size & delay-length
      { size: delayTime * 44100 },
      // {size: delayTime/frequencyMul * 44100},
      // Interpolate the size, over the duration
      // Shrink from full-len-of-delay, to 0 over the course of the delay

      // NOTE: This will be a pitch shift of 2 if freqMul === 1.... that's not very intuitive.
      // Should do a transform
      el.ms2samps(readSizeAdjuster),

      // TODO @Marcel: Make sure these chain together, while allowing the "delay time" to still specify how long is waited between nodes

      // Test case for making the delay only happen once... we need to shrink the delay time vs delay size.
      // Saying something like
      // "make the buffer = 1 sec ... but lets make the wait time 2 sec"
      // Why does this help?
      // Trying to find a solution for ensuring we play the fed-forward sound EXACTLY ONCE while doing this wonky manip for pitching / reverse...
      // Maybe I can wrap it in an envelope that evolves with the phasor, to make sure the phasor moves once, and closes out? Nah, don't think that'll work...
      // el.ms2samps( ela.interp(2*delayTimeMS, 0, 2*delayTime) ),

      // Test case for reversal
      // el.ms2samps( ela.interp(0, 2*delayTimeMS, delayTime) ),

      0,
      signal,
    )

    // console.log("Test: ,", frequencyMul/delayTime)
    // return delay;
    return el.mul(
      delay,
      // el.pow(el.hann(phasor), 0.2)
    )
  },

  reversedDelay() {
    // TODO @Marcel: Reverse the signal when delaying it
  },

  triangleWaveEl(
    t: number | NodeRepr_t,
    f: number,
    a: number,
    baseline: number,
  ) {
    const p = 1 / f
    const el_a = el.const({ value: a })
    const el_p = el.const({ value: p })
    const el_pOver4 = el.const({ value: p / 4 })

    // const modTimeTerm = modulo(t - p / 4, p)
    const el_modTimeTerm = el.mod(el.sub(t, el_pOver4), el_p)

    // const absValTerm = Math.abs(modTimeTerm - p / 2)
    const el_absValTerm = el.abs(
      el.sub(el_modTimeTerm, el.const({ value: p / 2 })),
    )

    // const triangle = (4 * a * absValTerm) / p
    const el_triangle = el.div(el.mul(4, el_a, el_absValTerm), el_p)

    return el.add(el_triangle, el.const({ value: baseline }))
  },

  arbitraryPathAlongSample(
    sampleInfo: SampleInfo,
    path: number | NodeRepr_t,
    pitchShift?: number,
  ) {
    // const reader = new SampleReader(sampleInfo)

    return el.mul(
      SubSampleReader.getPlayableNodeArbitraryPath(
        sampleInfo,
        path,
        pitchShift,
      ),
      3,
    )

    // return el.mul(reader.readArbitrarySamplePath(path, pitchShift), 3)
  },

  pathsAlongSample(sampleInfo: SampleInfo, paths: SamplePath[]) {
    const reader = new SampleReader(sampleInfo)

    const allItems = paths.map((path) => {
      return el.mul(reader.readSamplePath(path), 3)
    })

    return el.add(...allItems)
  },
}

export default ela

export class AyisenELTester {
  audioCore: AudioCore

  constructor(_audioCore: AudioCore) {
    this.audioCore = _audioCore
  }

  getTestSample() {
    const sampleNameToPick = getAtomValue(activeSampleAtom)
    if (!sampleNameToPick) return undefined

    const chosen =
      this.audioCore.sampleHandler?.loadedKits["Demo Kit (todo)"][
        sampleNameToPick
      ]

    console.warn("Available: ", this.audioCore.sampleHandler?.loadedKits)
    console.warn("Test Sample: ", chosen)

    console.warn("Available Kits: ", getAtomValue(availableSamplesAtom))

    // return this.audioCore.sampleHandler?.loadedKits['Ayisen']['Ayisen-Kit-1/Acoustic Guitar 1.wav'];
    // return this.audioCore.sampleHandler?.loadedKits['Ayisen']['Ayisen-Kit-1/Banjo 1.wav'];
    // return this.audioCore.sampleHandler?.loadedKits['Ayisen']['Ayisen-Kit-1/Electric Guitar 2.wav'];
    // return this.audioCore.sampleHandler?.loadedKits['Ayisen']['Ayisen-Kit-1/Keys 1.wav'];
    // return this.audioCore.sampleHandler?.loadedKits['Ayisen']['Ayisen-Kit-1/Kick 1.wav'];
    return chosen
    // return this.audioCore.sampleHandler?.loadedKits['Ayisen']['Ayisen-Kit-1/Synth 11.wav'];
  }

  interp() {
    // TODO @Marcel
  }

  playSamplePaths(paths: SamplePath[], sampleInfo?: SampleInfo) {
    const testSample = sampleInfo ?? this.getTestSample()
    if (!testSample) return null

    const renderable = ela.pathsAlongSample(testSample, paths)

    this.audioCore.core?.render(renderable)

    this.audioCore.core?.on("meter", function (e) {
      console.warn("Meter reading: ", e)
    })

    return renderable
  }

  pitchedDelay(sample?: SampleInfo) {
    let testSample = sample ?? this.getTestSample()

    if (!testSample) return

    // Main output
    let output = sampleInfoToSignal(testSample)

    // Piteched and delayed output
    // const pitchedAndDelayed = ela.pitchedDelay(4, output, -0.5)
    // output = el.add(output, pitchedAndDelayed);

    // const pitchedUpAfter = ela.pitchedDelay(4, pitchedAndDelayed, 2);
    // output = el.add(output, pitchedUpAfter);

    // const pitchedUpAfter2 = ela.pitchedDelay(2, pitchedAndDelayed, 4)
    // output = el.add(output, pitchedUpAfter2);

    // output = ela.frequencyShift(-100, output);
    // output = ela.marcelFuckedUpTest(2, output)

    const p = 0.05
    const pitchedAndDelayed = el.mul(ela.pitchedDelay(p, output, 2))
    const p2 = 0.06
    const pitchedAndDelayed2 = el.mul(ela.pitchedDelay(p2, output, 2))

    output = el.add(
      output,
      pitchedAndDelayed,
      // pitchedAndDelayed2
    )

    const input2 = sampleInfoToSignal(testSample)
    const normalDelay = ela.pitchedDelay(2, input2, 0.5)
    const grainDelay = ela.pitchedDelay(0.05, normalDelay, 2)
    output = grainDelay

    /**
     * For slime... we would want to modify the duration of sounds, but not shift the frequency.
     *
     * So... frequency multiple would be 1 always...
     *
     * But we want the number of repetitions of the grain to change.
     *
     * How would we achieve that ... hmm...
     *
     * Maybe we need to dig into the pitched and delay thing?
     *
     * We want to be farther behind sometimes, and not as far behind other times....
     *
     * So maybe we want to adjust something...
     *
     * But adjusting many of these params over time will effect pitch.
     *
     * The one we care about is "how long we hang on this repeat of this part"
     *
     * We're not incontrol of that with this grain method really...
     *
     * We're in control of the read -> write speed, and the read->write chunk size.
     *
     * We can make the chunk size increase maybe. That would effect the pitch though?
     * Nah, shouldn't if we do it right... but it will make things harder to NOT be choppy...
     *
     *
     *
     * Maybe we could have one delay, that's sweeping through and modifying the frequency in order to make things get wonky.
     * And then another delay that's correcting for it?!!
     *
     *
     *
     */

    // Some notes
    /**
     * - Sounds pretty good to have a delay that's longer than the signal (say 7 secs for these guitar samples)
     */

    // Render!
    const stats = this.audioCore.core?.render(output)
  }
}
