import Fidget, { PointEvent } from "../../Engines/VisualEngine/Fidget/Fidget"

import { BouncingBallModel } from "../../Models/bouncingBall"
import canvas from "../../Engines/VisualEngine/canvas/canvas"
import { ElasticBounceBetweenPoints1d } from "../../Models/ElasticBounceBtPoints1d"
import { getAtomValue } from "../../state/atomStore"
import { simWidthMeters } from "../../state/sim"
import { bounceAtom } from "./state"

export default class BounceSim extends Fidget {
  onDrag = (e: PointEvent) => {}

  onClick = (e: PointEvent) => {}

  // Render Frame
  renderFrame = async () => {
    if (!this.ctx) return

    this.clearCanvas()

    // I just want to render a ball bouncing for now lol
    if (!this.initT) return
    const elapsedTSeconds = (Date.now() - this.initT) / 1000

    // Get the active balls
    // TODO URGENT: Don't grab every time. Subscribe to updates with an atomEffect
    /**
     * Either effects, or I store the model inside something so as to not reconstruct it everytime....
     * That seems wasteful that I do that everytime.
     */
    const activeBalls = getAtomValue(bounceAtom)

    for (let activeBall of activeBalls) {
      // Use the model to simulate
      const currentH = BouncingBallModel.getModel(
        activeBall.dropHeight,
      ).valueAtT(elapsedTSeconds)
      const currentX = ElasticBounceBetweenPoints1d.getModel(
        activeBall.ballRadius,
        getAtomValue(simWidthMeters) - activeBall.ballRadius,
        activeBall.horizontalVelocity,
      ).valueAtT(elapsedTSeconds)

      const ballLocWorld = { x: currentX, y: currentH + activeBall.ballRadius } // bump up by r so ball bottom hits the ground
      const ballLocCanvas = this.world.worldSpaceToCanvasSpace(ballLocWorld)

      canvas.drawBubble(
        this.ctx,
        ballLocCanvas,
        this.world.worldLengthToCanvasLength(activeBall.ballRadius),
        activeBall.color,
        1,
      )
    }

    //
  }
}
