import Fidget, {
  PointEvent,
  SharedStateEntity,
} from "../../Engines/VisualEngine/Fidget/Fidget"
import AyisenMath from "../../libs/Math"
import FidgetUtils from "../../utilities"
import { R2 } from "../../libs/Math/R2"
import { FidgetId } from "../../constants/fidgetConsts"
import { RippleOnDrag } from "../EphemeralEngines/RippleOnDrag"

// JS Library for Ripple Animations

export default class Rippler extends Fidget {
  constructor(canvas: HTMLCanvasElement) {
    super(canvas, FidgetId.RIPPLE)

    // Just add the rippling EphemeralEngine :-)
    this.ephemeralEngines.push(new RippleOnDrag())
  }
}
