import React, { useEffect, useState } from "react"
import { SineSynth } from "./synth"
import { useAtomValue } from "jotai"
import { midiSettingAtom } from "../../state/midi_keyboard_state"
import MidiKeyboard, { Key } from "../../components/Keyboards/MidiKeyboard"
import Fidgets from "../../Fidgets"
import { FidgetId } from "../../constants/fidgetConsts"
import SimCanvas from "../../components/Sim/SimCanvas"
import VisualEngine from "../../Engines/VisualEngine"
import { SimInstrument } from "../../components/Sim/SimInstrument"
import BounceSim from "../Bounce/sim"

export function Sine() {
  // Shatter synth, new instance on mount
  const [synth, setSynth] = useState<SineSynth>(new SineSynth())

  return (
    <>
      <SimInstrument
        createNewSimulator={(canvas: HTMLCanvasElement) => {
          return new BounceSim(canvas, FidgetId.BOUNCE)
        }}
        synth={synth}
      />
    </>
  )
}
