import "./NiceNumberEditor.css"

export function NiceNumberEditor({
  label,
  value,
  onChange,
}: {
  label?: string
  value: number
  onChange: (v: number) => void
}) {
  return (
    <div className="niceNumberEditor">
      {label && <p>{label}</p>}
      <input
        type="number"
        value={value}
        onChange={(e) => {
          const value = e.target.value
          const num: number = Number(value)
          if (isNaN(num)) return

          onChange(num)
        }}
      />
    </div>
  )
}
