import { useAtomValue } from "jotai"
import Select from "react-select"
import { audioCoreAtom } from "../Engines/AudioEngine/core/AudioCore"
import { activeSampleAtom, availableSamplesAtom } from "../state/sampleState"
import { getAtomValue } from "../state/atomStore"
import React from "react"

export function SampleSelector() {
  // TODO (P0.5): Get the sample data to live in atom itself for rerender
  const audioCore = useAtomValue(audioCoreAtom)

  const availableSamples = useAtomValue(availableSamplesAtom)
  const activeSample = useAtomValue(activeSampleAtom)

  const sampleOpts = React.useMemo(
    () =>
      Object.values(availableSamples).flatMap((kitConfig) => {
        return Object.keys(kitConfig).map((key) => {
          return { label: key, value: key }
        })
      }),
    [availableSamples],
  )

  const onSelectOption = (option: any) => {
    audioCore.sampleHandler?.loadSpecificSampleName(option.value)
  }

  return (
    <Select
      options={sampleOpts}
      onChange={onSelectOption}
      value={{ value: activeSample, label: activeSample }}
      styles={{
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? "grey" : "black",
        }),
      }}
    />
  )
}
