import { R1 } from "../Math/R1"
import { R2 } from "../Math/R2"
import canvas from "../../Engines/VisualEngine/canvas/canvas"
import { CollisionResult, Obj2DType, Object2D } from "./Object"

export class Circle extends Object2D {
  center: R2.Vector
  radius: number

  constructor(center: R2.Vector, radius: number) {
    super()
    this.center = center
    this.radius = radius
  }

  static load(data: any): Circle | null {
    // todo
    return null
  }
  save() {
    // todo
  }

  getProjectedBoundsAlongAxis(axis: R2.Vector): R1.Range {
    const projectedCenter = R2.dot(axis, this.center)
    return {
      min: projectedCenter - this.radius,
      max: projectedCenter + this.radius,
    }
  }

  isPointWithin(p: R2.Vector): boolean {
    return R2.distance(this.center, p) < this.radius
  }

  computeCenter(): R2.Vector {
    return this.center
  }

  move(delta: R2.Vector): void {
    this.center = R2.add(this.center, delta)
  }

  detectCircleCollision(otherCircle: Circle): CollisionResult | null {
    const collision: CollisionResult | null = null

    // Check if they overlap
    const centerDist = R2.distance(this.center, otherCircle.center)
    const overlap = this.radius + otherCircle.radius - centerDist
    if (overlap > 0) {
      return {
        depth: overlap,
        axis: R2.norm(R2.sub(otherCircle.center, this.center)).norm,
      }
    }

    return null
  }

  draw(ctx: CanvasRenderingContext2D) {
    canvas.drawCircle(
      ctx,
      this.center.x,
      this.center.y,
      this.radius,
      this.color,
    )
  }
}
